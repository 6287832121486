/* @flow */

import moment from "moment"
import _ from "lodash"
import * as React from "react"
import * as Color from "helpers/color"
import * as Formatting from "helpers/formatting"
import { t as globalT } from "helpers/i18n"
import * as C from "rosters/WebpackRosters/consts"
import Text from "components/Text"
import Icon from "components/Icon"
import { fromJson } from "platform/models/model"
import * as DemandDataHelpers from "rosters/WebpackRosters/DemandData/helpers"
import * as DayViewHelperFuncs from "day_view/helpers/functions"
import { default as DayViewConstants } from "day_view/helpers/constants"
import * as Constants from "rosters/overview/helpers/constants"
import * as DayViewTypes from "day_view/types"
import * as DemandData from "rosters/overview/models/demandData"
import type { AllDataStreamData, DataStreamData } from "rosters/WebpackRosters/DemandData"
import { type KeyStatTypes } from "rosters/overview/models/viewOptions"
import * as Types from "rosters/overview/types"
import type {
  ScheduleRubyType,
  ScheduleType,
  ScheduleStats,
  LeaveRequestRubyType,
  BusinessHoursType,
  RosterRubyType,
  AICRubyType,
  ScheduleEditableParamsType,
  ScheduleEditableParamsRubyType,
  RubyStateType,
  UserRubyType,
  UserType,
  UserShallowRubyType,
  TransformedRubyStateType,
  PublishedScheduleRubyType,
  PublishedScheduleType,
  PredictionModifierType,
  RuleSetType,
  ScheduleValidationWithExpectedAndActual,
  ScheduleValidationWithBreakLengthAndPaidStatus,
  ScheduleValidationWithBreakStartErrorsType,
  GetExpectedBreaksStartTimesType,
  ScheduleBreakType,
  SpanOfHoursType,
  DataStreamRubyType,
} from "../types"
import type { CognitiveDemandConfigType } from "../models/cognitive/types"
import type { DateData } from "../models/demandData/types"

export const formatStat = Formatting.formatStat

export const transformUser = (u: UserRubyType): UserType => ({
  ...u,
  award_tags: (u.award_tags || "").split(",").filter((award_tag) => award_tag !== "" && award_tag != null),
})

export const transformUserFromShallowType = (u: UserShallowRubyType): UserType => ({
  ...Constants.DEFAULT_USER,
  ...u,
})

export const transformState = (ruby_state: RubyStateType): TransformedRubyStateType => ({
  labour_budgets: ruby_state.labour_budgets,
  sales_budgets: ruby_state.sales_budgets,
  metric_budgets: ruby_state.metric_budgets,
  new_labour_budgets_enabled: ruby_state.new_labour_budgets_enabled,
  budget_config: ruby_state.budget_config,
  enable_automatic_breaks: ruby_state.enable_automatic_breaks,
  config: {
    ...ruby_state.config,
    labour_budget_enabled: ruby_state.config.labour_budget_enabled,
    recent_pay_period_start: moveDateToWeekday(
      moment(ruby_state.config.recent_pay_period_start, C.DATE_FMT),
      ruby_state.config.roster_week_start_day
    ),
    platform_model: {
      user: fromJson(ruby_state.config.platform_model.user),
      schedule: fromJson(ruby_state.config.platform_model.schedule),
      location: fromJson(ruby_state.config.platform_model.location),
    },
  },
  stat_types: ruby_state.stat_types,
  team_groups: ruby_state.team_groups,
  cognitive_creator_configurations: ruby_state.cognitive_creator_configurations,
  cross_department_proficiencys: ruby_state.cross_department_proficiencys,
  locations: ruby_state.locations,
  teams: ruby_state.teams.map((team) => ({
    ...team,
    short_name: getTeamShortName(team.name),
  })),
  archived_teams: ruby_state.archived_teams.map((team) => ({
    ...team,
    short_name: getTeamShortName(team.name),
  })),
  departments: ruby_state.teams.map((team) => ({
    ...team,
    short_name: getTeamShortName(team.name),
  })),
  users: ruby_state.users.map(transformUserFromShallowType),
  user_qualifications: ruby_state.user_qualifications,
  department_qualifications: ruby_state.department_qualifications,
  qualifications: ruby_state.qualifications,
  training_logs: ruby_state.training_logs,
  position_groups: ruby_state.position_groups,
  positions: ruby_state.positions,
  employment_condition_sets: ruby_state.employment_condition_sets,
  data_stream_joins: ruby_state.data_stream_joins,
  head_count_maps: ruby_state.head_count_maps,
  data_streams: ruby_state.data_streams,
  shift_details: ruby_state.shift_details,
  oncost_configurations: ruby_state.oncost_configurations,
  roster_pattern_rdos: ruby_state.roster_pattern_rdos,
  roster_pattern_schedules: ruby_state.roster_pattern_schedules,
  roster_pattern_user_joins: ruby_state.roster_pattern_user_joins,
  roster_patterns: _.mapValues(
    _.groupBy(ruby_state.roster_patterns, (rp) => rp.id),
    (rps) => rps[0]
  ),
  rule_sets: ruby_state.rule_sets,
  templates: ruby_state.templates,
  template_user_joins: ruby_state.template_user_joins,
})

export const transformSchedule = (ruby_schedule: ScheduleRubyType): ScheduleType => {
  const { user_id, department_id, ...rest } = ruby_schedule
  return {
    ...rest,
    user_id: user_id != null ? user_id : -1,
    department_id: department_id != null ? department_id : -1,
  }
}

export const transformPublishedSchedule = (ruby_schedule: PublishedScheduleRubyType): PublishedScheduleType => {
  const { user_id, department_id, ...rest } = ruby_schedule
  return {
    ...rest,
    user_id: user_id != null ? user_id : -1,
    department_id: department_id != null ? department_id : -1,
  }
}

export const transformLeave = (leave: LeaveRequestRubyType): LeaveRequestRubyType => ({
  ...leave,
  daily_breakdown: leave.daily_breakdown || [],
  start_time: leave.start_time && moment(leave.start_time).format(C.DATE_TIME_FMT),
  finish_time: leave.finish_time && moment(leave.finish_time).format(C.DATE_TIME_FMT),
})

export const transformScheduleToRubyType = (ruby_schedule: ScheduleType): ScheduleRubyType => {
  const { user_id, department_id, ...rest } = ruby_schedule
  return {
    ...rest,
    user_id: user_id < 0 ? null : user_id,
    department_id: department_id < 0 ? null : department_id,
  }
}

const getBudgetData = (data, date, data_stream_id) =>
  data[date]?.[String(data_stream_id)]?.filter((d) => d.stat_type === Constants.BUDGET_STAT_TYPE) || []

const usesMultipleDates = (strategy, budget_data) => strategy === Constants.AVERAGE_OF_DATES && budget_data.length === 0

const getCombinedDates = (strategy, budget_data, config, date) =>
  usesMultipleDates(strategy, budget_data) ? config?.multiple_dates || [] : [date]

const getGrowthPercentage = (config, data_stream_id) =>
  config?.growth_percentages_by_ds_id?.[data_stream_id] || config?.growth_percentage || 1

const getDatumsToCombine = (dates_to_combine, data, data_stream_id) =>
  _.flatten(dates_to_combine?.map((pred_date) => data[pred_date]?.[String(data_stream_id)] || []))

const getElandoPrediction = (data, date, data_stream_id) =>
  data[date]?.[String(data_stream_id)]?.filter((d) => d.stat_type === Constants.ELANDO_STAT_TYPE) || []

const createPrediction = (datums, date, stat_type, data_stream_id, config, percentages, strategy, span) =>
  config?.uses_fine_tune
    ? DemandData.createPredictionFromFineTune(datums, date, stat_type, data_stream_id, percentages, span)
    : DemandData.createPredictionFromGrowthPercentage(
        datums,
        date,
        stat_type,
        data_stream_id,
        getGrowthPercentage(config, data_stream_id),
        strategy,
        config,
        span
      )

export const createModifiedPredictions = (
  data_streams: Array<DataStreamRubyType>,
  demand_data: { [date: string]: { [data_stream_id: string]: Array<DateData> } },
  demand_config: ?CognitiveDemandConfigType,
  date: string,
  modifiers_by_data_stream_by_date: { [date: string]: { [ds_id: string]: Array<PredictionModifierType> } },
  forecasting_strategy: string,
  span: SpanOfHoursType
): Array<DateData> =>
  _.flatten(
    data_streams.map<Array<DateData>, _>((data_stream) => {
      const { id: data_stream_id } = data_stream
      const budget_data = getBudgetData(demand_data, date, data_stream_id)
      const dates_to_combine = getCombinedDates(forecasting_strategy, budget_data, demand_config, date)
      let datums_to_combine = getDatumsToCombine(dates_to_combine, demand_data, data_stream_id)
      const elando_prediction = getElandoPrediction(demand_data, date, data_stream_id)

      if (elando_prediction.length > 0) {
        datums_to_combine = datums_to_combine.concat(elando_prediction)
      }

      const by_stat_type = _.groupBy(datums_to_combine, (datum) => datum.stat_type)
      const growth_percentages = modifiers_by_data_stream_by_date[date]?.[String(data_stream_id)] || []

      const prediction_by_stat_type = _.mapValues(by_stat_type, (datums, stat_type) =>
        createPrediction(
          datums,
          date,
          stat_type,
          data_stream_id,
          demand_config,
          growth_percentages,
          forecasting_strategy,
          span
        )
      )
      // $FlowFixMe mapValues is mixed but in this case it is okay
      return Object.values(prediction_by_stat_type)
    })
  )

export const getDroppableId = (date: string, group_id: ?string, user_id: ?number): string =>
  date + "~" + String(group_id) + "~" + String(user_id)
export const extractParamsFromDroppableId = (
  droppable_id: string
): {|
  date: string,
  group_id: ?string,
  user_id: ?number,
|} => {
  const [date, group_id, user_id] = droppable_id.split("~")
  return {
    date,
    group_id: group_id === "null" ? null : group_id,
    user_id: Number(user_id) || null,
  }
}

export const getOffsetFromRecentStartPayPeriod = (
  recent_pay_period_start: moment,
  pay_period_length: number,
  date: string
): number => Math.abs(recent_pay_period_start.diff(moment(date, C.DATE_FMT), "days")) - pay_period_length

export const getMostRecentStartPayPeriod = (
  recent_pay_period_start: moment,
  pay_period_length: number,
  date: string
): moment =>
  moment(date, C.DATE_FMT).subtract(
    getOffsetFromRecentStartPayPeriod(recent_pay_period_start, pay_period_length, date),
    "days"
  )

export const transformEditableParamsToRubyType = (
  changes: ScheduleEditableParamsType
): ScheduleEditableParamsRubyType => {
  const clone: ScheduleEditableParamsRubyType = { ...changes }
  if (clone.user_id != null) {
    clone.user_id = clone.user_id < 0 ? null : clone.user_id
  }
  if (clone.department_id != null) {
    clone.department_id = clone.department_id < 0 ? null : clone.department_id
  }
  if (clone.shift_detail_id) {
    clone.shift_detail_id = clone.shift_detail_id < 0 ? null : clone.shift_detail_id
  }
  return clone
}

export const timeToMinutes: (time: string) => number = _.memoize((time: string) =>
  moment.duration(time, "HH:mm").asMinutes()
)

const addLeadingZero: (time: string) => string = (time: string) => (time.length === 1 ? "0" + time : time)
const correctTimeFormat: (time: string) => string = (time: string) => {
  const hrMin = time.split(":")
  return addLeadingZero(hrMin[0]) + ":" + addLeadingZero(hrMin[1])
}
const formatDuration: (time: moment$MomentDuration) => string = (time: moment$MomentDuration) =>
  correctTimeFormat(String(Math.floor(time.asHours())) + ":" + String(time.minutes()))

export const minutesToTime = (minutes: number): string => {
  const min = minutes < 0 ? 0 : minutes
  return formatDuration(moment.duration(min, "minutes"))
}

export const addMinutesToTime: (time: string, minutes: number) => string = (time, minutes) =>
  formatDuration(moment.duration(time, "HH:mm").add(minutes, "minutes"))

export const minutesToUsersTime: (minutes: number) => string = _.memoize((minutes: number): string => {
  const mins = minutes < 0 ? 0 : minutes
  const min = mins % 60
  const hours = Math.floor(mins / 60)

  return window.time_formatter.rosters_time(moment().hours(hours).minutes(min))
})

export const formatToUsersTime: (time: string) => string = (time) => {
  const timeParts = time.split(":")
  const hour = Number(timeParts[0])
  const min = Number(timeParts[1])

  return window.time_formatter.rosters_time(moment().hours(hour).minutes(min))
}

export const dateTimeToMinutes = (date_time: string, reference_date: ?string): number => {
  if (reference_date == null) {
    return timeToMinutes(date_time.split(" ")[1])
  } else {
    return moment(date_time, C.DATE_TIME_FMT).diff(moment(reference_date, C.DATE_FMT), "minutes")
  }
}

export const dateTimeToMinutesWithOffset = (date_time: string, reference_date: ?string): number => {
  if (reference_date == null) {
    return timeToMinutes(date_time.split(" ")[1])
  } else {
    const hour_difference = moment(date_time, C.DATE_TIME_FMT).diff(moment(reference_date, C.DATE_FMT), "minutes")

    const todays_offset = new Date(date_time).getTimezoneOffset()
    const start_of_day_offset = new Date(reference_date).getTimezoneOffset()
    const difference_offset = todays_offset - start_of_day_offset

    return Math.round(hour_difference - difference_offset)
  }
}

export const minutesToDateTime = (minutes: number, reference_date: string): string => {
  const extraDays = Math.floor(minutes / (24 * 60))
  if (extraDays) {
    const newDate = moment(reference_date, C.DATE_FMT).add(extraDays, "days").format(C.DATE_FMT)
    return newDate + " " + minutesToTime(minutes - 24 * 60 * extraDays)
  }
  return reference_date + " " + minutesToTime(minutes)
}

export const dateTimeToDate = (date_time: ?string): string => moment(date_time, C.DATE_TIME_FMT).format("YYYY-MM-DD")

export const totalMinutesOnDate = (time_period: { +finish: ?string, +start: ?string }, date: string): number => {
  if (
    time_period.start === "" ||
    time_period.finish === "" ||
    time_period.start == null ||
    time_period.finish == null
  ) {
    return 0
  }

  const start = moment(time_period.start)
  const finish = moment(time_period.finish)
  const startOfDate = moment(date).startOf("day")
  const endOfDate = moment(date).endOf("day")

  if (start.isBefore(startOfDate) && finish.isBefore(startOfDate)) {
    return 0
  }

  const clampedStart = start.isBefore(startOfDate) ? startOfDate : start
  const clampedFinish = finish.isAfter(endOfDate) ? endOfDate : finish

  const difference = clampedFinish.diff(clampedStart, "minutes")

  return difference > 0 ? difference : 0
}

export const dateTimeToDoW: (date_time: string) => number = (date_time: string) => moment(date_time).day()

export const dateTimeToTime: (date_time: string) => string = _.memoize((date_time: string): string =>
  moment(date_time, C.DATE_TIME_FMT).format("HH:mm")
)

export const floatTimeToUserTime: (time: number) => string = (time: number) => minutesToUsersTime(Math.round(time * 60))

export const dateTimeToFloatTime = (date_time: string, reference_date: ?string): number =>
  dateTimeToMinutes(date_time, reference_date) / 60.0

export const getBestTextColor: (bg_color: string) => "white" | "text" = Color.getBestTextColor

// Returns 0 if moment is invalid
export const dateTimeToUnix: (date_time: ?string) => number = _.memoize(
  (date_time: ?string): number => moment(date_time, C.DATE_TIME_FMT).unix() || 0
)

export const fixedCostForUser = (user: UserType, start_date?: string, finish_date?: string): number => {
  const number_of_costed_days = numberOfDaysInEmploymentRangeForPeriod(user, start_date, finish_date)

  return user.fixed_cost_daily_salary * number_of_costed_days
}

export const totalScheduleCosts = (
  allAics: Array<AICRubyType>,
  users?: Array<UserType>,
  start_date?: string,
  finish_date?: string
): number => {
  if (users?.length === 0) {
    return 0
  } else if (users === undefined) {
    return allAics.reduce((acc, aic) => (Number(aic.cost) || 0) + acc, 0)
  }
  const fixedCostUsers = users.filter((user) => user.has_fixed_cost_salary_allocation)
  const fixedUsersCosts = fixedCostUsers.reduce((acc, user) => fixedCostForUser(user, start_date, finish_date) + acc, 0)
  const aicsGroupedByUserId = _.groupBy(allAics, (aic) => aic.user_id)
  const scheduleCosts = _.entries(aicsGroupedByUserId).map(([user_id, aics]) => {
    const user = users.find((user) => user.id === Number(user_id)) || Constants.DEFAULT_USER
    return user.has_fixed_cost_salary_allocation ? 0 : aics.reduce((acc, aic) => (Number(aic.cost) || 0) + acc, 0)
  })
  return scheduleCosts.reduce((acc, cost) => cost + acc, 0) + fixedUsersCosts
}

export const getStatsFromSchedules = (
  schedules: Array<ScheduleType>,
  schedule_to_aics: { [s_id: string]: Array<AICRubyType> },
  maybe_total_sales?: number,
  users?: Array<UserType>,
  start_date?: string,
  finish_date?: string,
  maybe_data_count?: number,
  maybe_schedule_to_aics_with_oncosts?: { [s_id: string]: Array<AICRubyType> },
  maybe_schedule_to_overtime_aics?: { [s_id: string]: Array<AICRubyType> },
  maybe_labour_budget?: number,
  maybe_leave_data?: { total_hours: number, total_paid_hours: number }
): ScheduleStats => {
  const total_sales: number = maybe_total_sales || 0
  const data_count: number = maybe_data_count || 1
  const schedule_to_aics_with_oncosts = maybe_schedule_to_aics_with_oncosts || {}
  const schedule_to_overtime_aics = maybe_schedule_to_overtime_aics || {}
  const labour_budget = maybe_labour_budget || 0
  const total_schedules = schedules.length
  const all_aics: Array<AICRubyType> = _.flatten(schedules.map((s) => schedule_to_aics[String(s.id)] || []))
  const all_aics_with_oncosts: Array<AICRubyType> = maybe_schedule_to_aics_with_oncosts
    ? _.flatten(schedules.map((s) => schedule_to_aics_with_oncosts[String(s.id)] || []))
    : []
  const all_overtime_aics: Array<AICRubyType> = maybe_schedule_to_overtime_aics
    ? _.flatten(schedules.map((s) => schedule_to_overtime_aics[String(s.id)] || []))
    : []

  const total_schedule_costs: number = totalScheduleCosts(all_aics, users, start_date, finish_date)
  const total_schedule_costs_with_oncosts: number = all_aics_with_oncosts.reduce(
    (acc, aic) => (Number(aic.cost) || 0) + acc,
    0
  )
  const total_schedule_overtime_costs: number = all_overtime_aics.reduce((acc, aic) => (Number(aic.cost) || 0) + acc, 0)
  const total_hours_per_sched = schedules.map((s) => {
    const start_unix: number = dateTimeToUnix(s.start)
    const finish_unix: number = dateTimeToUnix(s.finish)
    if (start_unix === 0 || finish_unix === 0) {
      return 0
    }

    const unpaid_breaks_time_minutes = s.schedule_breaks
      .filter((sb) => !sb.paid || sb.paid_meal_break)
      .reduce((totalBreakMinutes, scheduleBreak) => totalBreakMinutes + scheduleBreak.length, 0)

    const span_of_hours = (finish_unix - start_unix) / (60 * 60) // seconds to hours
    return span_of_hours - unpaid_breaks_time_minutes / 60
  })
  const total_hours = Math.round(total_hours_per_sched.reduce((a, s) => a + s, 0) * 100) / 100
  const total_hrs_incl_leave = maybe_leave_data ? total_hours + maybe_leave_data.total_paid_hours : total_hours
  const total_hrs_incl_leave_and_unpaid = maybe_leave_data ? total_hours + maybe_leave_data.total_hours : total_hours
  const avg_cost_per_rostered_hour: number = total_hours !== 0 ? total_schedule_costs / total_hours : 0
  const vacant_schedules_count: number = schedules.filter((s) => s.user_id === -1).length

  return {
    total_schedules: total_schedules / data_count,
    total_sales: total_sales / data_count,
    total_schedule_costs: total_schedule_costs / data_count,
    total_schedule_costs_with_oncosts: total_schedule_costs_with_oncosts / data_count,
    total_schedule_overtime_costs: total_schedule_overtime_costs / data_count,
    avg_cost_per_rostered_hour,
    total_hours: total_hours / data_count,
    total_hrs_incl_leave: total_hrs_incl_leave / data_count,
    total_hrs_incl_leave_and_unpaid: total_hrs_incl_leave_and_unpaid / data_count,
    wage_percentage_of_revenue: total_sales !== 0 ? total_schedule_costs / total_sales : 0,
    wage_percentage_of_revenue_with_oncosts: total_sales !== 0 ? total_schedule_costs_with_oncosts / total_sales : 0,
    wage_percentage_of_revenue_vs_budget: labour_budget !== 0 && total_sales !== 0 ? labour_budget / total_sales : 0,
    sales_per_labor_hour: total_hours !== 0 ? total_sales / total_hours : 0,
    sales_per_labor_hour_vs_budget: labour_budget !== 0 ? total_sales / labour_budget : 0,
    vacant_schedules_count: vacant_schedules_count,
  }
}

const numberOfDaysBetweenDates = (startDate: Date, finishDate: Date): number => {
  const MS_PER_SECOND = 1000
  const SECONDS_PER_HOUR = 3600
  const HOURS_PER_DAY = 24
  const MS_PER_DAY = MS_PER_SECOND * SECONDS_PER_HOUR * HOURS_PER_DAY

  const timeDifference = finishDate.getTime() - startDate.getTime()
  const differenceInDays = Math.round(timeDifference / MS_PER_DAY)

  return differenceInDays + 1
}

const numberOfDaysInEmploymentRangeForPeriod = (user: UserType, start_date?: string, finish_date?: string): number => {
  if (!start_date || !finish_date) {
    return 0
  }
  const employment_start = user.employment_start_date ? moment(user.employment_start_date) : null
  const employment_finish = user.employment_end_date ? moment(user.employment_end_date) : null

  const start_of_period = moment(start_date)
  const end_of_period = moment(finish_date)

  const adjusted_start =
    !employment_start || employment_start.isBefore(start_of_period) ? start_of_period : employment_start
  const adjusted_finish =
    !employment_finish || employment_finish.isAfter(end_of_period) ? end_of_period : employment_finish

  return numberOfDaysBetweenDates(adjusted_start.toDate(), adjusted_finish.toDate())
}

const getScheduleLength = (schedule) =>
  moment.duration(moment(schedule.finish).diff(moment(schedule.start))).asHours() || 0
const getRuleSetForEmployee = (rule_sets: Array<RuleSetType>, schedule: ScheduleType): ?RuleSetType => {
  const default_rule_set = rule_sets.filter((rule_set) => rule_set.is_default)[0]
  const applicable_rule_sets = rule_sets.filter((rule_set) => rule_set.employee_ids.includes(schedule.user_id))
  if (applicable_rule_sets.length === 0) {
    return default_rule_set
  }
  return applicable_rule_sets.sort((a, b) => a.priority - b.priority)[0]
}

const getExpectedPaidBreaks = (rules, schedule_length) => {
  const paidRules = rules.filter((rule) => rule.paid && !rule.paid_meal_break)
  return paidRules.filter((rule) => rule.shift_length <= schedule_length)
}

const getExpectedPaidMealBreaks = (rules, schedule_length) => {
  const paidRules = rules.filter((rule) => rule.paid && rule.paid_meal_break)
  return paidRules.filter((rule) => rule.shift_length <= schedule_length)
}

const getExpectedUnpaidBreaks = (rules, schedule_length) => {
  const unpaidRules = rules.filter((rule) => !rule.paid)
  return unpaidRules.filter((rule) => rule.shift_length <= schedule_length)
}

const getScheduleBreaksPaid = (schedule) =>
  schedule.schedule_breaks.filter((schedule_break) => schedule_break.paid && !schedule_break.paid_meal_break)
const getScheduleBreaksPaidMealBreaks = (schedule) =>
  schedule.schedule_breaks.filter((schedule_break) => schedule_break.paid && schedule_break.paid_meal_break)
const getScheduleBreaksUnpaid = (schedule) => schedule.schedule_breaks.filter((schedule_break) => !schedule_break.paid)
const getActualBreaksWithStartAndFinishTimes = (schedule: ScheduleType): Array<ScheduleBreakType> =>
  schedule.schedule_breaks
    .filter((schedule_break) => schedule_break.id != null)
    .filter((schedule_break) => schedule_break.start != null && schedule_break.finish != null)
    .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())

const getExpectedBreaksStartTimes = (
  employee_rule_set,
  schedule_start,
  schedule_length
): Array<GetExpectedBreaksStartTimesType> => {
  if (!employee_rule_set || !schedule_start || !schedule_length) {
    return []
  }
  const expectedRules = employee_rule_set.rules.filter(
    (rule) => rule.shift_length <= schedule_length && rule.applicable_before_surpassed_hours
  )

  return expectedRules
    .map((rule) => {
      const absh = rule.applicable_before_surpassed_hours
      return absh != null
        ? {
            start: moment(schedule_start).add(absh, "hours"),
            break_length: rule.break_length,
            paid: rule.paid,
            paid_meal_break: rule.paid_meal_break,
          }
        : null
    })
    .filter(Boolean)
    .sort((a, b) => a.start.valueOf() - b.start.valueOf())
}

export const validateSurpassedHoursBreakRulesMatchRosteredBreaks = (args: {
  rule_sets: Array<RuleSetType>,
  schedule: ScheduleType,
}): Array<ScheduleValidationWithBreakStartErrorsType> => {
  const employee_rule_set = getRuleSetForEmployee(args.rule_sets, args.schedule)
  const roster_with_times = employee_rule_set != null ? employee_rule_set.roster_with_times : false
  if (
    args.rule_sets.length === 0 ||
    !roster_with_times ||
    args.schedule.schedule_breaks.every((br) => br.automatic_break)
  ) {
    return []
  }
  const scheduleStart = args.schedule.start
  const scheduleLength = getScheduleLength(args.schedule)
  const expectedBreakTimes = getExpectedBreaksStartTimes(employee_rule_set, scheduleStart, scheduleLength)
  const actualBreakTimes = getActualBreaksWithStartAndFinishTimes(args.schedule)
  const breakIdsMatchedToRules = []
  const expectedUnmatchedRules = expectedBreakTimes.filter((expectedBreak) => {
    const matchingActualBreak = actualBreakTimes.find(
      (actualBreak) =>
        actualBreak &&
        actualBreak.length >= expectedBreak.break_length &&
        actualBreak.paid === expectedBreak.paid &&
        actualBreak.paid_meal_break === expectedBreak.paid_meal_break &&
        !breakIdsMatchedToRules.includes(actualBreak.id) &&
        moment(actualBreak.start).valueOf() <= expectedBreak.start.valueOf()
    )
    if (matchingActualBreak != null) {
      breakIdsMatchedToRules.push(matchingActualBreak.id)
      return false
    } else {
      return true
    }
  })
  return expectedUnmatchedRules.map((unmatchedRule) => ({
    type: "breaks_start_before_surpassed_hours_not_matching",
    error: unmatchedRule,
  }))
}

export const areBreaksWithinBuffer = (args: {
  buffered_time: moment,
  checkStartOrFinish: "start" | "finish",
  schedule_breaks: Array<ScheduleBreakType>,
  // Requires the schedule start for overnight shifts as the moments for the breaks just
  // base their date on the schedule start date(regardless of if the break itself is an
  // overnight shift)
  schedule_start: moment,
}): boolean => {
  if (args.checkStartOrFinish === "start") {
    const any_breaks_before = args.schedule_breaks.some((schedule_break) => {
      const break_start = moment(schedule_break.start)
      // Now if the break is before the schedule start we can calculate whether its
      // before the buffer after adjusting the date of the break to make sure it's
      // on the correct day of the shift
      if (break_start.isBefore(args.schedule_start)) {
        return break_start.add(1, "days").isBefore(args.buffered_time)
      }
      return break_start.isBefore(args.buffered_time)
    })
    return any_breaks_before
  } else {
    const any_breaks_after = args.schedule_breaks.some((schedule_break) => {
      const break_finish = moment(schedule_break.finish)
      if (break_finish.isBefore(args.schedule_start)) {
        return break_finish.add(1, "days").isAfter(args.buffered_time)
      }
      return break_finish.isAfter(args.buffered_time)
    })
    return any_breaks_after
  }
}

export const getApplicableHoursBuffer = (args: { rule_sets: Array<RuleSetType>, schedule: ScheduleType }): number =>
  getRuleSetForEmployee(args.rule_sets, args.schedule)?.applicable_hours_buffer || 0

export const validateBreakRuleLengthsMatchRosteredBreaks = (args: {
  rule_sets: Array<RuleSetType>,
  schedule: ScheduleType,
}): Array<ScheduleValidationWithBreakLengthAndPaidStatus> => {
  const employeeRuleSet = getRuleSetForEmployee(args.rule_sets, args.schedule)
  const employeeRules = employeeRuleSet != null ? employeeRuleSet.rules : []
  if (employeeRules.length === 0) return []

  const {
    expectedPaidBreaks,
    actualPaidBreaks,
    expectedPaidMealBreaks,
    actualPaidMealBreaks,
    expectedUnpaidBreaks,
    actualUnpaidBreaks,
  } = expectedAndActualBreaks(employeeRules, args.schedule)

  const expectedPaidBreakLengths = expectedPaidBreaks
    .sort((a, b) => a.break_length - b.break_length)
    .map((expectedBreak) => expectedBreak.break_length)

  const actualPaidBreakLengths = actualPaidBreaks
    .sort((a, b) => a["length"] - b["length"])
    .map((actualBreak) => actualBreak["length"])

  const expectedPaidMealBreakLengths = expectedPaidMealBreaks
    .sort((a, b) => a.break_length - b.break_length)
    .map((expectedBreak) => expectedBreak.break_length)

  const actualPaidMealBreakLengths = actualPaidMealBreaks
    .sort((a, b) => a["length"] - b["length"])
    .map((actualBreak) => actualBreak["length"])

  const expectedUnpaidBreakLengths = expectedUnpaidBreaks
    .sort((a, b) => a.break_length - b.break_length)
    .map((expectedBreak) => expectedBreak.break_length)

  const actualUnpaidBreakLengths = actualUnpaidBreaks
    .sort((a, b) => a["length"] - b["length"])
    .map((actualBreak) => actualBreak["length"])

  const shortPaidBreaks = expectedPaidBreakLengths.filter((breakLength, index) => {
    if (actualPaidBreakLengths[index] < breakLength) return breakLength
  })

  const shortPaidMealBreaks = expectedPaidMealBreakLengths.filter((breakLength, index) => {
    if (actualPaidMealBreakLengths[index] < breakLength) return breakLength
  })

  const shortUnpaidBreaks = expectedUnpaidBreakLengths.filter((breakLength, index) => {
    if (actualUnpaidBreakLengths[index] < breakLength) return breakLength
  })

  return [
    {
      type: "paid_breaks_not_matching",
      missing_break_lengths: shortPaidBreaks,
    },
    {
      type: "paid_meal_breaks_not_matching",
      missing_break_lengths: shortPaidMealBreaks,
    },
    {
      type: "unpaid_breaks_not_matching",
      missing_break_lengths: shortUnpaidBreaks,
    },
  ]
}

export const validateBreakRuleCountsMatchRosteredBreaks = (args: {
  rule_sets: Array<RuleSetType>,
  schedule: ScheduleType,
}): Array<ScheduleValidationWithExpectedAndActual> => {
  const employeeRuleSet = getRuleSetForEmployee(args.rule_sets, args.schedule)
  const employeeRules = employeeRuleSet != null ? employeeRuleSet.rules : []
  if (employeeRules.length === 0) return []

  const {
    expectedPaidBreaks,
    actualPaidBreaks,
    expectedPaidMealBreaks,
    actualPaidMealBreaks,
    expectedUnpaidBreaks,
    actualUnpaidBreaks,
  } = expectedAndActualBreaks(employeeRules, args.schedule)

  return [
    {
      type: "paid_breaks_not_matching",
      expected: expectedPaidBreaks.length,
      actual: actualPaidBreaks.length,
    },
    {
      type: "paid_meal_breaks_not_matching",
      expected: expectedPaidMealBreaks.length,
      actual: actualPaidMealBreaks.length,
    },
    {
      type: "unpaid_breaks_not_matching",
      expected: expectedUnpaidBreaks.length,
      actual: actualUnpaidBreaks.length,
    },
  ]
}

const expectedAndActualBreaks = (employeeRules, schedule) => ({
  expectedPaidBreaks: getExpectedPaidBreaks(employeeRules, getScheduleLength(schedule)),
  actualPaidBreaks: getScheduleBreaksPaid(schedule),
  expectedPaidMealBreaks: getExpectedPaidMealBreaks(employeeRules, getScheduleLength(schedule)),
  actualPaidMealBreaks: getScheduleBreaksPaidMealBreaks(schedule),
  expectedUnpaidBreaks: getExpectedUnpaidBreaks(employeeRules, getScheduleLength(schedule)),
  actualUnpaidBreaks: getScheduleBreaksUnpaid(schedule),
})

export const getStatsFromSchedule = (
  schedule: ScheduleType
): {|
  breaks_time_minutes: number,
  span_of_hours: number,
  worked_hours: number,
|} => {
  const start_unix: number = dateTimeToUnix(schedule.start)
  const finish_unix: number = dateTimeToUnix(schedule.finish)
  if (start_unix === 0 || finish_unix === 0) {
    return {
      span_of_hours: 0,
      worked_hours: 0,
      breaks_time_minutes: 0,
    }
  }
  const unpaid_breaks_time_minutes: number = schedule.schedule_breaks
    .filter((sb) => !sb.paid || sb.paid_meal_break)
    .reduce((totalBreakMinutes, scheduleBreak) => totalBreakMinutes + scheduleBreak.length, 0)

  const span_of_hours = (finish_unix - start_unix) / (60 * 60) // seconds to hours
  const worked_hours = span_of_hours - unpaid_breaks_time_minutes / 60
  return {
    span_of_hours,
    worked_hours,
    breaks_time_minutes: unpaid_breaks_time_minutes,
  }
}

export const whitenColor: (color: string) => string = Color.whitenColor

export const brightenColor: (color: string) => string = Color.brightenColor

export const darkenColorIfTooLight: (color: string) => string = Color.darkenColorIfTooLight

export const backgroundIsLight: (color: string) => boolean = Color.backgroundIsLight

export const textColor: (color: string) => string = Color.textColor

export const darkerColor: (color: string) => string = Color.darkerColor

export const lightColor: (color: string) => string = Color.lightColor
export const getNormalisedDepColor: (color: string) => string = Color.lightColor

export const lighterColor: (color: string) => string = Color.lighterColor
export const getNormalisedDepBorderColor: (color: string) => string = Color.lighterColor

export const lightestColor: (color: string) => string = Color.lightestColor

export const getRosterForDate = (rosters: Array<RosterRubyType>, date: string): ?RosterRubyType =>
  _.find(rosters, (r) => r.start <= date && r.end >= date)

const includesTranslation = (string: string, matches: Array<string>) =>
  matches.findIndex((match) => string.indexOf(globalT(`js.rosters.rosters_overview.schedule_card.${match}`)) !== -1) !==
  -1

export const getLeaveTypeEmoji = (leave_type: string, size: "s" | "tiny"): React.Node => {
  const lower = leave_type.toLowerCase()
  if (includesTranslation(lower, ["sick", "personal"])) {
    const emoji = "🤒"
    return <Text align="center">{emoji}</Text>
  } else if (includesTranslation(lower, ["annual", "holiday", "vacation"])) {
    return <Icon color="black" size={size} type="airplanemode-active" />
  } else {
    return <Icon color="black" size={size} type="airplanemode-active" />
  }
}

// Some changes to a schedule affect other parts of the schedule
// for example, changing the date causes start time and finish time to need to change
// this function keeps all this unnormalised data in check.

// Subtracts items from the original array
// eg: [1,2,3,3,4] - [2,3] == [1,3,4]
export function deleteItemsFromArray<T>(array: Array<T>, items_to_delete: Array<T>): Array<T> {
  return items_to_delete.reduce(
    (acc, item) => {
      const idx = acc.indexOf(item)
      if (idx !== -1) {
        acc.splice(idx, 1)
      }
      return acc
    },
    [...array]
  )
}

export const getTeamShortName = Formatting.getTeamShortName

// Gets the start time and finish time for a leave card on the given date (C.DATE_TIME_FMT for both)
// If either start or finish is null, then the leave request does fall on the given date.
export const getTimesFromLeaveOnDate = (
  leaveRequest: LeaveRequestRubyType,
  date: string
): { finish: ?string, start: ?string } => {
  const inside_date = leaveRequest.start <= date && leaveRequest.finish >= date
  if (!inside_date) {
    return { start: null, finish: null }
  }
  if (leaveRequest.all_day) {
    if (leaveRequest.start_time != null && leaveRequest.finish_time != null) {
      // These times may come from the breakdown (see visibleLeaveByDateByUser in selectors).
      // We alter the leave request itself rather than getting times out of the breakdown like `leaveRequest.daily_breakdown.find((br) => br[DATE] === date)` because there may be
      // multiple shift summaries on a single date, and we can't get multiple times from a single leave request in this context. Also, each leave request is currently being
      // duplicated over multiple days for rendering purposes in visibleLeaveByDateByUser. Therefore, each leave request needs to also be duplicated over a single date
      // if it has multiple breakdown summaries on a single date.
      return { start: leaveRequest.start_time, finish: leaveRequest.finish_time }
    }
    return { start: date + " 00:00:00", finish: date + " 23:59:59" }
  }
  if (leaveRequest.start_time == null || leaveRequest.finish_time == null) {
    return { start: null, finish: null }
  }
  const leave_raw_start: string = leaveRequest.start_time
  const leave_raw_finish: string = leaveRequest.finish_time
  const leave_start: string = leaveRequest.start + " " + (leave_raw_start.split(" ")[1] || "")
  const leave_finish: string = leaveRequest.start + " " + (leave_raw_finish.split(" ")[1] || "")
  const leave_actual_finish: string =
    leave_finish < leave_start
      ? moment(leaveRequest.start, C.DATE_FMT).add(1, "day").format(C.DATE_FMT) +
        " " +
        (leave_raw_finish.split(" ")[1] || "")
      : leave_finish
  return {
    start: leave_start,
    finish: leave_actual_finish,
  }
}

export const getPublishableVacantSchedules = (schedules: Array<ScheduleType>): Array<ScheduleType> =>
  schedules.filter((schedule) => {
    const has_complete_data = schedule.start && schedule.finish && schedule.department_id && schedule.department_id > 0
    const cutoff_time = moment().add(1, "hour")
    const is_after_cutoff = moment(schedule.start).isAfter(cutoff_time, "seconds")

    return has_complete_data && is_after_cutoff
  })

export const getAllDates = (start_date: moment, finish_date: moment): Array<moment> =>
  _.range(0, finish_date.diff(start_date, "days") + 1, 1).map((i) => start_date.clone().add(i, "days"))

export const getAllDatesStr = (start_date: moment, finish_date: moment): Array<string> =>
  getAllDates(start_date, finish_date).map((d) => d.format(C.DATE_FMT))
export const getAllDatesStrFromStr: (start_date: string, finish_date: string) => Array<string> = _.memoize(
  (start_date: string, finish_date: string) =>
    getAllDatesStr(moment(start_date, C.DATE_FMT), moment(finish_date, C.DATE_FMT)),
  (start_date: string, finish_date: string) => start_date + "~" + finish_date
)

export const getAllDatesFromStr = (start_date: string, finish_date: string): Array<moment> =>
  getAllDates(moment(start_date, C.DATE_FMT), moment(finish_date, C.DATE_FMT))

export const getWeeksWorthOfDates: (date: string) => Array<string> = _.memoize((date: string) =>
  getAllDatesStr(moment(date, C.DATE_FMT), moment(date, C.DATE_FMT).add(6, "days"))
)

export const formatDateShortForm: (date: string) => string = _.memoize((date: string) =>
  moment(date, C.DATE_FMT).format("dd D")
)
export const formatDateMedForm: (date: string) => string = _.memoize((date: string) =>
  moment(date, C.DATE_FMT).format("ddd DD MMM")
)
export const formatDateLongForm: (date: string) => string = _.memoize((date: string) =>
  moment(date, C.DATE_FMT).format("ddd Do MMM")
)
export const formatDateVeryLongForm: (date: string) => string = _.memoize((date: string) =>
  moment(date, C.DATE_FMT).format("ddd · Do MMM YY")
)

// Takes an improper datetime and makes it proper (YYYY-MM-DD HH:mm:ss)
// Eg "2019-01-01 9:45 pm" -> "2019-01-01 21:45:00"
// Eg "2019-01-01 9:45" -> "2019-01-01 09:45:00"
export const normaliseDateTime: (date_time: string) => string = _.memoize((date_time: string) =>
  moment(date_time, ["YYYY-MM-DD h:mm a", C.DATE_TIME_FMT]).format(C.DATE_TIME_FMT)
)

export const mergeAndAddStats = (
  a: { [minute: string]: number },
  b: { [minute: string]: number }
): { [minute: string]: number } => {
  if (!(a && b)) {
    return a || b
  }

  const new_obj = {}
  const objs = [a, b]

  objs.forEach((obj) => {
    _.toPairs(obj).forEach(([k, v]) => {
      new_obj[k] = (new_obj[k] || 0) + obj[k]
    })
  })
  return new_obj
}

const mapTimeToModifier = (modifiers: Array<PredictionModifierType>): { [time: string]: number } => {
  if (modifiers.length <= 2) {
    return {}
  }
  const linear_interp = (x: number, y: number, a: number) => x * (1 - a) + y * a
  const sorted: Array<PredictionModifierType> = _.sortBy(modifiers, (m: PredictionModifierType) => m.time)
  return sorted.reduce((acc, pm, i) => {
    const start = pm
    const end = sorted[i + 1]
    if (end == null) {
      return acc
    }
    const range = _.range(start.time, end.time, 15)
    const diff = end.time - start.time
    range.map((n) => {
      acc[String(n)] = linear_interp(start.growth_percentage, end.growth_percentage, (n - start.time) / diff)
    })
    return acc
  }, {})
}

export const mapStatsAndTime = (
  prediction: Types.DateData,
  original_stat: boolean
): Array<{ [minute: string]: string | number }> => {
  const date_m = moment(prediction.date, C.DATE_FMT).startOf("day")

  return _.toPairs(original_stat ? prediction.original_stat_by_15 : prediction.stat_by_15).map(([time, value]) => ({
    time: date_m.clone().add(Number(time), "minutes").format(C.DATE_TIME_FMT),
    ...(original_stat ? { original_stat: Number(value) } : { stat: Number(value) }),
  }))
}

export const mapDataToStatsBy15 = (
  prediction: Types.PredictionResponse,
  original_stat: boolean
): { [minute: string]: number } => {
  const date_m = moment(prediction.date, C.DATE_FMT).startOf("day")

  return _.fromPairs(
    prediction.data.map((datum) => [
      String(moment(datum.time, C.DATE_TIME_FMT).diff(date_m, "minutes")),
      Number(original_stat ? datum.original_stat : datum.stat) || 0,
    ])
  )
}

export const applyPercentageModifiers = (
  a: { [minute: string]: number },
  modifiers: Array<PredictionModifierType>
): { [minute: string]: number } => {
  const mapToModifier = mapTimeToModifier(modifiers)
  return _.mapValues(a, (v, time) => v * (mapToModifier[toNearest15Str(time)] || 1))
}

export const toNearest15Str = (num: string): string => String(toNearest15(Number(num)))
export const toNearest15 = (num: number): number => Math.round(num / 15) * 15

export const applyPercentageModifier = (
  a: { [minute: string]: number },
  modifier: number
): { [minute: string]: number } => _.mapValues(a, (v) => v * modifier)

export const dateTimeToMin = DemandDataHelpers.dateTimeToMin
export const dateTimeToMinWithOffset = DemandDataHelpers.dateTimeToMinWithOffset

export const dateToWeekday: (date: string) => number = _.memoize(
  (date: string) => moment(date, C.DATE_FMT).isoWeekday() % 7
)

// https://stackoverflow.com/questions/4467539/javascript-modulo-gives-a-negative-result-for-negative-numbers
export const mod = (num: number, mod: number): number => ((num % mod) + mod) % mod

// The `|| 7` here is because '0' is ruby standard for sunday wday, but iso standard is 7
export const moveDateToWeekday = (date: moment, weekday: number): moment =>
  date.isoWeekday() === (weekday || 7) ? date : date.clone().isoWeekday(weekday)

export const addDaysToDate: (date: string, days: number) => string = _.memoize(
  (date: string, days: number) => moment(date, C.DATE_FMT).add(days, "days").format(C.DATE_FMT),
  (date, days) => date + "~" + String(days)
)

export const getWeekdayFromWeekdayName = (weekday: string): number => {
  switch (weekday) {
    case "Sunday":
      return 0
    case "Monday":
      return 1
    case "Tuesday":
      return 2
    case "Wednesday":
      return 3
    case "Thursday":
      return 4
    case "Friday":
      return 5
    case "Saturday":
      return 6
    default:
      return -1
  }
}

export default { transformState }

// Adjusts buisness hours so they include open and closed times
export const include_open_and_close_in_bh = (
  bhs: Array<BusinessHoursType>,
  time_to_open: ?number,
  time_to_close: ?number
): Array<BusinessHoursType> => {
  if (time_to_open == null || time_to_close == null) {
    return bhs
  }
  if (time_to_open === 0 && time_to_close === 0) {
    return bhs
  }
  const clone: Array<BusinessHoursType> = bhs.map((bh) => ({ ...bh }))
  const earliest_bh: BusinessHoursType = _.sortBy(clone, (bh) => bh.start)[0]
  const new_start = earliest_bh.start - time_to_open
  earliest_bh.start = new_start < 0 ? 0 : new_start
  const latest_bh: BusinessHoursType = _.sortBy(clone, (bh) => -1 * bh.finish)[0]
  const new_finish = latest_bh.finish + time_to_close
  latest_bh.finish = new_finish < 0 ? 0 : new_finish
  const grouped: { [id: string]: BusinessHoursType } = _.mapValues(
    _.groupBy(bhs, (bh) => bh.id),
    (bh) => bh[0]
  )
  // This will replace the old bh with the adjusted bh
  const new_grouped: { [id: string]: BusinessHoursType } = {
    ...grouped,
    [earliest_bh.id]: earliest_bh,
    [latest_bh.id]: latest_bh,
  }
  return _.values(new_grouped)
}

export const time_inside_bh = (bhs: Array<BusinessHoursType>, min: number): boolean =>
  _.some(bhs.map((b) => b.start <= min && min < b.finish))

type TimeSeriesType = Array<TimeType>
type TimeType = { [key: string]: number, name: string }

export const generateChartData = (
  processed_department_data: Array<DayViewTypes.DepartmentWithStatsType>,
  processed_total_department_data: DayViewTypes.DepartmentWithStatsType,
  projected_department_data: Array<DayViewTypes.DepartmentWithStatsOnDateType>,
  projected_total_department_data: DayViewTypes.DepartmentWithStatsType,
  prediction: AllDataStreamData,
  actual_data: AllDataStreamData,
  visible_hours_start_half_hour: number,
  visible_hours_end_half_hour: number
): TimeSeriesType => {
  // range() crops off the last value (eg, range(10,14) === [10,11,12,13]), so make sure to add an extra interval.
  const xAxisTimes: Array<number> = _.range(
    visible_hours_start_half_hour * 30,
    visible_hours_end_half_hour * 30 + 15,
    15
  )
  // Map through time (eg. 600, 630, 660) and count the amount of schedules occuring at that time.
  return xAxisTimes.map((minute: number) => {
    const department_to_staff_count: { [department_name: string]: number } = processed_department_data.reduce(
      (acc, dep) => {
        acc[dep.name] = dep.stat_by_15_with_assisted[String(minute)] || 0
        return acc
      },
      {}
    )
    const projected_department_to_staff_count: { [department_name: string]: number } = projected_department_data.reduce(
      (acc, dep) => {
        const key_name = globalT("js.rosters.predictive_workforce_graph.lines.recommended_label", {
          team_name: dep.name,
        })
        acc[key_name] = dep.stat_by_15_with_assisted[String(minute)] || 0
        return acc
      },
      {}
    )

    const static_entries: { [line_name: string]: number } = {
      [DayViewConstants.GRAPH_ENTRIES.total.key]: processed_total_department_data.stat_by_15[String(minute)],
      [DayViewConstants.GRAPH_ENTRIES.total_predicted.key]: projected_total_department_data.stat_by_15[String(minute)],
    }
    const dss: Array<DataStreamData> = _.values(prediction)
    const actual_dss: Array<DataStreamData> = _.values(actual_data)

    const data_stream_datas: { [line_name: string]: number } = _.fromPairs(
      dss.map((ds_data: DataStreamData) => [ds_data.data_stream_id, ds_data.stat_by_15[String(minute)]])
    )
    const actual_data_stream_datas: { [line_name: string]: number } = _.fromPairs(
      actual_dss.map((ds_data: DataStreamData) => [
        "actual_" + ds_data.data_stream_id,
        ds_data.stat_by_15[String(minute)],
      ])
    )

    return {
      ...projected_department_to_staff_count,
      // $FlowFixMe no better way to do this
      ...department_to_staff_count,
      ...static_entries,
      ...data_stream_datas,
      ...actual_data_stream_datas,
      name: DayViewHelperFuncs.minutesToTime(minute),
    }
  })
}

export const ageFromDOB = (dob: ?string, current_time: string): ?number => {
  if (dob == null) {
    return null
  }
  return moment(current_time).diff(dob, "years")
}

const taken_ids: Array<number> = []

export const getMockID = (): number => {
  const id = Math.round(Math.random() * (Number.MAX_SAFE_INTEGER / 2) + 1)
  if (taken_ids.includes(id)) {
    return getMockID()
  }
  taken_ids.push(id)
  return id
}

export const isInStaffMode = (day_view_roster_view: string, is_day_view: boolean, roster_view: string): boolean =>
  (day_view_roster_view === "staff" && is_day_view) || (!is_day_view && roster_view === "staff")

export const isANotRosteredShiftCard = (schedule: ScheduleType): boolean =>
  (schedule.needs_acceptance || schedule.acceptance_status === "accepted") && !schedule.start && !schedule.finish

export const isDateWithinRange = (date: string, dateRangeStart: string, dateRangeFinish: ?string): boolean => {
  const dateMoment = moment(date)
  const dateRangeStartMoment = moment(dateRangeStart)

  if (dateRangeFinish != null) {
    const dateRangeFinishMoment = moment(dateRangeFinish)

    return dateMoment.isBetween(dateRangeStartMoment, dateRangeFinishMoment, "days", "[]")
  }

  return dateMoment.isSameOrAfter(dateRangeStartMoment)
}

export const hasValidRosterMetrics = (metrics: Array<string> | Array<KeyStatTypes>): boolean => {
  if (!metrics?.length) return false
  if (metrics.includes(null)) return false
  if (_.isEqual(metrics, ["choose_stat"])) return false
  return true
}
