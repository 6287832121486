import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["square", "innerSquare", "title"]

  static values = {
    isGlobalOrgChart: Boolean,
  }
  connect() {
    const square = this.squareTarget
    square.style.transition = "all 0.3s ease"
    if (this.isGlobalOrgChartValue) {
      square.addEventListener("click", this.handleClickOnSquare.bind(this))
      this.compressSquare(square)
    } else {
      this.setNormalState()
    }
  }

  disconnect() {
    this.setNormalState()
  }

  handleClickOnSquare(event) {
    if (this.hasInnerSquareTarget) {
      if (this.innerSquareTarget.contains(event.target)) {
        event.stopImmediatePropagation()
        return
      }
    }
    const square = this.squareTarget
    if (square.dataset.compressed) {
      this.expandSquare(square)
    } else {
      this.compressSquare(square)
    }
    event.stopPropagation()
  }

  // Helper method to expand square
  expandSquare(square) {
    if (this.isGlobalOrgChartValue) {
      this.setNormalState()
      if (this.hasInnerSquareTarget) {
        this.innerSquareTarget.classList.remove("hidden")
      }
      this.makeTitleBig()
      if (square.dataset.compressed) {
        delete square.dataset.compressed
      }
    }
  }

  compressSquare(square) {
    square.dataset.compressed = "true"
    this.setCompactState(square)
    this.makeTitleSmall()
    if (this.hasInnerSquareTarget) {
      this.innerSquareTarget.classList.add("hidden")
    }
  }

  // Method to render squares normally
  setNormalState() {
    const square = this.squareTarget
    square.classList.remove("h-3", "pt-2", "pb-6", "hover:drop-shadow-xl")
  }

  // Helper method to set compact state
  setCompactState(square) {
    if (this.isGlobalOrgChartValue) {
      square.classList.add("h-3", "pt-2", "pb-6", "hover:drop-shadow-xl")
    }
  }

  makeTitleSmall() {
    const title = this.titleTarget
    title.classList.remove("font-semibold", "text-gray-700", "text-base")
    title.classList.add("text-xs", "text-gray-800", "font-normal", "truncate", "group-hover/square:text-gray-900")
  }

  makeTitleBig() {
    const title = this.titleTarget
    title.classList.remove("text-xs", "text-gray-800", "font-normal", "truncate", "group-hover/square:text-gray-900")
    title.classList.add("font-semibold", "text-gray-700", "text-base")
  }
}
