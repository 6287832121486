/*
File generated by js-routes 1.4.9
Based on Rails 8.0.0.beta1 routes of Web::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, result, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_invitation_from_profile => /staff/:id/accept_invitation(.:format)
  // function(id, options)
  accept_invitation_from_profile_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"accept_invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// accept_schedules => /rosters/accept_schedules(.:format)
  // function(options)
  accept_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"accept_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_switching => /switch_account/:new_user_id(.:format)
  // function(new_user_id, options)
  account_switching_path: Utils.route([["new_user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"switch_account",false],[2,[7,"/",false],[2,[3,"new_user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// account_switching_siblings => /account_switching/siblings(.:format)
  // function(options)
  account_switching_siblings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account_switching",false],[2,[7,"/",false],[2,[6,"siblings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// active_users => /active_users(.:format)
  // function(options)
  active_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"active_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// approve_timesheet => /timesheets/:id/approve(.:format)
  // function(id, options)
  approve_timesheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approve_user_qualification_user => /staff/:id/approve_user_qualification(.:format)
  // function(id, options)
  approve_user_qualification_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"approve_user_qualification",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// approved_leave_requests => /leave_requests/approved(.:format)
  // function(options)
  approved_leave_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_requests",false],[2,[7,"/",false],[2,[6,"approved",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ats_integrations => /ats/integrations(.:format)
  // function(options)
  ats_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ats",false],[2,[7,"/",false],[2,[6,"integrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// automatic_break_section_update => /settings/automatic_break_section_update(.:format)
  // function(options)
  automatic_break_section_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"automatic_break_section_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// award_tags => /tags/award(.:format)
  // function(options)
  award_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[6,"award",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// award_template_allowances => /award_templates/:id/allowances(.:format)
  // function(id, options)
  award_template_allowances_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"award_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"allowances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// award_template_awards => /award_templates/:id/awards(.:format)
  // function(id, options)
  award_template_awards_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"award_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// award_template_outer_limits => /award_templates/:id/outer_limits(.:format)
  // function(id, options)
  award_template_outer_limits_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"award_templates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"outer_limits",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// backpay_all_timesheets => /timesheets/backpay_all(.:format)
  // function(options)
  backpay_all_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"backpay_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// backpay_for_single_timesheet => /timesheets/:id/backpay(.:format)
  // function(id, options)
  backpay_for_single_timesheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"backpay",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// base_roster_notifications => /roster/notify_staff(.:format)
  // function(options)
  base_roster_notifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"notify_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// billing_subscriptions => /billing_subscriptions(.:format)
  // function(options)
  billing_subscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"billing_subscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// budgets => /budgets(.:format)
  // function(options)
  budgets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"budgets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bulk_backfill_legal_names_users => /staff/bulk_backfill_legal_names(.:format)
  // function(options)
  bulk_backfill_legal_names_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"bulk_backfill_legal_names",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_deactivate_users => /staff/bulk_deactivate(.:format)
  // function(options)
  bulk_deactivate_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"bulk_deactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_rdos_destroy => /rdos/bulk_rdos_destroy(.:format)
  // function(options)
  bulk_rdos_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rdos",false],[2,[7,"/",false],[2,[6,"bulk_rdos_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_update_automatic_break_rule_set_priorities => /settings/bulk_update_automatic_break_rule_set_priorities(.:format)
  // function(options)
  bulk_update_automatic_break_rule_set_priorities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"bulk_update_automatic_break_rule_set_priorities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// check_is_sso_user => /users/sso/login(.:format)
  // function(options)
  check_is_sso_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sso",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cognitive_creation_algorithm_webhook => /cognitive_creator/hook/:algorithm_id/:uuid(.:format)
  // function(algorithm_id, uuid, options)
  cognitive_creation_algorithm_webhook_path: Utils.route([["algorithm_id",true],["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cognitive_creator",false],[2,[7,"/",false],[2,[6,"hook",false],[2,[7,"/",false],[2,[3,"algorithm_id",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cognitive_creation_bulk_get_config => /cognitive_creator/bulk_get_config(.:format)
  // function(options)
  cognitive_creation_bulk_get_config_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cognitive_creator",false],[2,[7,"/",false],[2,[6,"bulk_get_config",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cognitive_creation_config => /cognitive_creator/config/:department_id(.:format)
  // function(department_id, options)
  cognitive_creation_config_path: Utils.route([["department_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cognitive_creator",false],[2,[7,"/",false],[2,[6,"config",false],[2,[7,"/",false],[2,[3,"department_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cognitive_creation_poll => /cognitive_creator/poll/:uuid(.:format)
  // function(uuid, options)
  cognitive_creation_poll_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"cognitive_creator",false],[2,[7,"/",false],[2,[6,"poll",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cognitive_creation_run => /cognitive_creator/run(.:format)
  // function(options)
  cognitive_creation_run_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"cognitive_creator",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// complete_onboarding => /dashboard/complete_onboarding(.:format)
  // function(options)
  complete_onboarding_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"complete_onboarding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// contract_templates => /contract_templates(.:format)
  // function(options)
  contract_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contract_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// copy_custom_events_to_customers_organisation => /organisations/:id/copy_custom_events_to_customers(.:format)
  // function(id, options)
  copy_custom_events_to_customers_organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"copy_custom_events_to_customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_or_update_sales_target => /rosters_overview/sales_target(.:format)
  // function(options)
  create_or_update_sales_target_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"sales_target",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_pay_condition_set => /staff/:id/contract/make_amendment(.:format)
  // function(id, options)
  create_pay_condition_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contract",false],[2,[7,"/",false],[2,[6,"make_amendment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// create_roster_notifications => /roster/notify_staff/:dates(/:department_id)(.:format)
  // function(dates, options)
  create_roster_notifications_path: Utils.route([["dates",true],["department_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"notify_staff",false],[2,[7,"/",false],[2,[3,"dates",false],[2,[1,[2,[7,"/",false],[3,"department_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// create_timesheet_reminders => /timesheets/remind(.:format)
  // function(options)
  create_timesheet_reminders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"remind",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// csv_importer => /import/:type(.:format)
  // function(type, options)
  csv_importer_path: Utils.route([["type",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[3,"type",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// current_roster => /roster/current(.:format)
  // function(options)
  current_roster_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// current_timesheet_for_user => /timesheets/for/:user_id/:date(.:format)
  // function(user_id, date, options)
  current_timesheet_for_user_path: Utils.route([["user_id",true],["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"for",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// custom_event => /custom_events/:id(.:format)
  // function(id, options)
  custom_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// custom_events => /custom_events(.:format)
  // function(options)
  custom_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"custom_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// dashboard => /dashboard(/:department_id)(.:format)
  // function(options)
  dashboard_path: Utils.route([["department_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[1,[2,[7,"/",false],[3,"department_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// dashboard_live_insights_check_ongoing_job => /dashboard/live_insights/check_ongoing_job(.:format)
  // function(options)
  dashboard_live_insights_check_ongoing_job_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"live_insights",false],[2,[7,"/",false],[2,[6,"check_ongoing_job",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dashboard_live_insights_get_data => /dashboard/live_insights/get_data(.:format)
  // function(options)
  dashboard_live_insights_get_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"live_insights",false],[2,[7,"/",false],[2,[6,"get_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dashboard_live_insights_get_data_by_location => /dashboard/live_insights/get_data_by_location(.:format)
  // function(options)
  dashboard_live_insights_get_data_by_location_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"live_insights",false],[2,[7,"/",false],[2,[6,"get_data_by_location",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dashboard_live_insights_get_departments => /dashboard/live_insights/get_departments(.:format)
  // function(options)
  dashboard_live_insights_get_departments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"live_insights",false],[2,[7,"/",false],[2,[6,"get_departments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// dashboard_live_insights_set_widget_setting => /dashboard/live_insights/set_widget_setting(.:format)
  // function(options)
  dashboard_live_insights_set_widget_setting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"live_insights",false],[2,[7,"/",false],[2,[6,"set_widget_setting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// data_streams => /integrations/data(*rest)(.:format)
  // function(options)
  data_streams_path: Utils.route([["rest",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[1,[5,[3,"rest",false],false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// default_validation_settings => /settings/default_validation(.:format)
  // function(options)
  default_validation_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"default_validation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delete_payroll_integration => /integrations/payroll/:id(.:format)
  // function(id, options)
  delete_payroll_integration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// delete_rosters_overview_schedule_break => /rosters_overview/schedules/:schedule_id/schedule_breaks/:id(.:format)
  // function(schedule_id, id, options)
  delete_rosters_overview_schedule_break_path: Utils.route([["schedule_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"schedule_id",false],[2,[7,"/",false],[2,[6,"schedule_breaks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// delete_rosters_overview_schedules => /rosters_overview/schedules/:schedule_id(.:format)
  // function(schedule_id, options)
  delete_rosters_overview_schedules_path: Utils.route([["schedule_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"schedule_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// demand_forecasting => /integrations/forecasting(.:format)
  // function(options)
  demand_forecasting_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"forecasting",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// demo_account => /demo_account/:id(.:format)
  // function(id, options)
  demo_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"demo_account",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// demo_account_create => /demo_account(.:format)
  // function(options)
  demo_account_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"demo_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// demo_account_delete => /demo_account/:id(.:format)
  // function(id, options)
  demo_account_delete_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"demo_account",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// demo_account_index => /demo_account(.:format)
  // function(options)
  demo_account_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"demo_account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// demo_creation_status => /demo_account/status/:organisation_id(.:format)
  // function(organisation_id, options)
  demo_creation_status_path: Utils.route([["organisation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"demo_account",false],[2,[7,"/",false],[2,[6,"status",false],[2,[7,"/",false],[2,[3,"organisation_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// department_sort_order => /department_sort_order/:id(.:format)
  // function(id, options)
  department_sort_order_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"department_sort_order",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_data_stream => /integrations/data/data_stream/:id(.:format)
  // function(id, options)
  destroy_data_stream_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[7,"/",false],[2,[6,"data_stream",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// destroy_stat_type => /stat_type/:id(.:format)
  // function(id, options)
  destroy_stat_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stat_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// details_payroll_uk_user_employee_benefit => /payroll/uk/users/:user_id/employee_benefits/:id/details(.:format)
  // function(user_id, id, options)
  details_payroll_uk_user_employee_benefit_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[6,"uk",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employee_benefits",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// disconnect_payroll_integration => /integrations/payroll/:id/disconnect(.:format)
  // function(id, options)
  disconnect_payroll_integration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"disconnect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_allowance => /allowances/:id/edit(.:format)
  // function(id, options)
  edit_allowance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"allowances",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_award => /awards/:id/edit(.:format)
  // function(id, options)
  edit_award_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_department_groups => /department_groups/:id(.:format)
  // function(id, options)
  edit_department_groups_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"department_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_iframe_leave_requests => /leave_requests/edit_iframe(.:format)
  // function(options)
  edit_iframe_leave_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_requests",false],[2,[7,"/",false],[2,[6,"edit_iframe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_location => /locations/:id/edit(.:format)
  // function(id, options)
  edit_location_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_platform_model => /platform/objects/:id/edit(.:format)
  // function(id, options)
  edit_platform_model_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_platform_model_record => /platform/objects/:model_id/records/:id/edit(.:format)
  // function(model_id, id, options)
  edit_platform_model_record_path: Utils.route([["model_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"model_id",false],[2,[7,"/",false],[2,[6,"records",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_platform_role => /platform/roles/:id/edit(.:format)
  // function(id, options)
  edit_platform_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_qualifications => /qualifications(.:format)
  // function(options)
  edit_qualifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"qualifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// edit_roster => /roster/:date(.:format)
  // function(date, options)
  edit_roster_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_settings_pay_group => /settings/pay_groups/:id/edit(.:format)
  // function(id, options)
  edit_settings_pay_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"pay_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// elando => /elando(.:format)
  // function(options)
  elando_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"elando",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// elando_retrain => /elando/retrain(.:format)
  // function(options)
  elando_retrain_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"elando",false],[2,[7,"/",false],[2,[6,"retrain",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employment_condition_set_template_options_positions => /positions/employment_condition_set_template_options(.:format)
  // function(options)
  employment_condition_set_template_options_positions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[6,"employment_condition_set_template_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// enable_tfa_on_profile => /profile/enable_tfa(.:format)
  // function(options)
  enable_tfa_on_profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"enable_tfa",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_all_results_timesheets => /timesheets/export_all_results(.:format)
  // function(options)
  export_all_results_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"export_all_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_all_timesheets => /timesheets/export_all(.:format)
  // function(options)
  export_all_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"export_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// export_roles_matrix => /platform/export/roles(.:format)
  // function(options)
  export_roles_matrix_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_timesheets => /timesheets/export(.:format)
  // function(options)
  export_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// generate_report_leave_balances => /leave_balances/generate_report(.:format)
  // function(options)
  generate_report_leave_balances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_balances",false],[2,[7,"/",false],[2,[6,"generate_report",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_ab_rule_set_employees => /settings/ab_rule_set_to_employees(.:format)
  // function(options)
  get_ab_rule_set_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"ab_rule_set_to_employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_available_balance_leave_index => /leave/get_available_balance(.:format)
  // function(options)
  get_available_balance_leave_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"get_available_balance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_awards => /award_templates(.:format)
  // function(options)
  get_awards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"award_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// get_custom_awards => /awards(.:format)
  // function(options)
  get_custom_awards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// get_data_stream_history => /integrations/data/recent/:id(.:format)
  // function(id, options)
  get_data_stream_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[7,"/",false],[2,[6,"recent",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// get_employee_id_to_name => /settings/employee_id_to_name(.:format)
  // function(options)
  get_employee_id_to_name_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"employee_id_to_name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_ppt_awards_settings => /ppt_settings_for_awards(.:format)
  // function(options)
  get_ppt_awards_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ppt_settings_for_awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// get_stat_type_history => /integrations/data/recent_stat_type/:id(.:format)
  // function(id, options)
  get_stat_type_history_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[7,"/",false],[2,[6,"recent_stat_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// get_validation_to_employee => /settings/roster_validation_to_employee(.:format)
  // function(options)
  get_validation_to_employee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"roster_validation_to_employee",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// groups_and_schedule_tags => /tag_groups/groups_and_schedule_tags(.:format)
  // function(options)
  groups_and_schedule_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tag_groups",false],[2,[7,"/",false],[2,[6,"groups_and_schedule_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// hierarchy_platform_model => /platform/objects/:id/hierarchy(.:format)
  // function(id, options)
  hierarchy_platform_model_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"hierarchy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// hris_enable => /hris/enable(.:format)
  // function(options)
  hris_enable_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"hris",false],[2,[7,"/",false],[2,[6,"enable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// import_platform_store_index => /platform/store/import(.:format)
  // function(options)
  import_platform_store_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"store",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// import_user_entered => /storestats/import/user_entered(.:format)
  // function(options)
  import_user_entered_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"storestats",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"user_entered",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// internal_graphql => /graphql(.:format)
  // function(options)
  internal_graphql_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"graphql",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// is_holiday => /api/is_holiday(.:format)
  // function(options)
  is_holiday_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"is_holiday",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// job_branding_ats_seek_index => /ats/seek/job_branding(.:format)
  // function(options)
  job_branding_ats_seek_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ats",false],[2,[7,"/",false],[2,[6,"seek",false],[2,[7,"/",false],[2,[6,"job_branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// key_alerts_update => /notifications_and_tasks/key_alerts/:id(.:format)
  // function(id, options)
  key_alerts_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications_and_tasks",false],[2,[7,"/",false],[2,[6,"key_alerts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// learn_platform => /learn/platform(.:format)
  // function(options)
  learn_platform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"platform",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// learn_rostering => /learn/rostering(.:format)
  // function(options)
  learn_rostering_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"learn",false],[2,[7,"/",false],[2,[6,"rostering",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// leave_awards => /leave/awards(.:format)
  // function(options)
  leave_awards_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// leave_fill_strategies => /leave_fill_strategies(.:format)
  // function(options)
  leave_fill_strategies_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_fill_strategies",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// leave_fill_strategy_destroy => /leave_fill_strategy_destroy/:id(.:format)
  // function(id, options)
  leave_fill_strategy_destroy_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_fill_strategy_destroy",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// leave_fill_strategy_update => /leave_fill_strategy_update/:id(.:format)
  // function(id, options)
  leave_fill_strategy_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_fill_strategy_update",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// leave_hours_update_leave_requests => /leave_requests/leave_hours_update(.:format)
  // function(options)
  leave_hours_update_leave_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_requests",false],[2,[7,"/",false],[2,[6,"leave_hours_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// leave_index => /leave(.:format)
  // function(options)
  leave_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// leave_requests => /leave_requests(.:format)
  // function(options)
  leave_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// locations => /locations(.:format)
  // function(options)
  locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// locations_search => /locations/search(.:format)
  // function(options)
  locations_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lock_all_timesheets => /timesheets/lock_all(.:format)
  // function(options)
  lock_all_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"lock_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// managed_document => /managed_documents/:id(.:format)
  // function(id, options)
  managed_document_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// metabase_root => /metabase(.:format)
  // function(options)
  metabase_root_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"metabase",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// mobile_app_available_shift_replacements => /mobile_app/replacements/available(.:format)
  // function(options)
  mobile_app_available_shift_replacements_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"replacements",false],[2,[7,"/",false],[2,[6,"available",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mobile_app_leave => /mobile_app/leave(.:format)
  // function(options)
  mobile_app_leave_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"leave",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mobile_app_my_schedules => /mobile_app/schedules/me(/:date)(.:format)
  // function(options)
  mobile_app_my_schedules_path: Utils.route([["date",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"me",false],[2,[1,[2,[7,"/",false],[3,"date",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// mobile_app_my_shifts => /mobile_app/timesheets/me(/:date)(.:format)
  // function(options)
  mobile_app_my_shifts_path: Utils.route([["date",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"me",false],[2,[1,[2,[7,"/",false],[3,"date",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// mobile_app_new_team_leave_form => /mobile_app/leave/new_team_leave/:id(.:format)
  // function(id, options)
  mobile_app_new_team_leave_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"new_team_leave",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mobile_app_overview => /mobile_app/overview(.:format)
  // function(options)
  mobile_app_overview_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// mobile_app_profile_save_i_nine => /mobile_app/profile/i_nine/save(.:format)
  // function(options)
  mobile_app_profile_save_i_nine_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"i_nine",false],[2,[7,"/",false],[2,[6,"save",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mobile_app_team_leave => /mobile_app/leave/team(.:format)
  // function(options)
  mobile_app_team_leave_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"team",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// mobile_app_team_leave_panel => /mobile_app/leave/team_leave_panel(/:start)(/:finish)(.:format)
  // function(options)
  mobile_app_team_leave_panel_path: Utils.route([["start",false],["finish",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"team_leave_panel",false],[2,[1,[2,[7,"/",false],[3,"start",false]],false],[2,[1,[2,[7,"/",false],[3,"finish",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mobile_app_team_schedules => /mobile_app/schedules/team(/:date)(.:format)
  // function(options)
  mobile_app_team_schedules_path: Utils.route([["date",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"team",false],[2,[1,[2,[7,"/",false],[3,"date",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// mobile_app_timesheet_approvals_date => /mobile_app/timesheets/approvals/:date(.:format)
  // function(date, options)
  mobile_app_timesheet_approvals_date_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"approvals",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mobile_app_timesheets => /mobile_app/timesheets(.:format)
  // function(options)
  mobile_app_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"mobile_app",false],[2,[7,"/",false],[2,[6,"timesheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_allowance => /allowances/new(.:format)
  // function(options)
  new_allowance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"allowances",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_award => /awards/new(.:format)
  // function(options)
  new_award_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_iframe_leave_requests => /leave_requests/new_iframe(.:format)
  // function(options)
  new_iframe_leave_requests_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave_requests",false],[2,[7,"/",false],[2,[6,"new_iframe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_lock_roster => /rosters/:date/new_lock(.:format)
  // function(date, options)
  new_lock_roster_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[3,"date",false],[2,[7,"/",false],[2,[6,"new_lock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_platform_model => /platform/objects/new(.:format)
  // function(options)
  new_platform_model_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_platform_model_record => /platform/objects/:model_id/records/new(.:format)
  // function(model_id, options)
  new_platform_model_record_path: Utils.route([["model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"model_id",false],[2,[7,"/",false],[2,[6,"records",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_platform_role => /platform/roles/new(.:format)
  // function(options)
  new_platform_role_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_settings_pay_group => /settings/pay_groups/new(.:format)
  // function(options)
  new_settings_pay_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"pay_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user => /staff/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// notes => /notes(.:format)
  // function(options)
  notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// notifications_and_tasks => /notifications_and_tasks(/*alerts_tab)(.:format)
  // function(options)
  notifications_and_tasks_path: Utils.route([["alerts_tab",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications_and_tasks",false],[2,[1,[2,[7,"/",false],[5,[3,"alerts_tab",false],false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// onboarding_conf_index => /onboarding_conf(.:format)
  // function(options)
  onboarding_conf_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding_conf",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// onboarding_create_document_group => /onboarding/documents/group(.:format)
  // function(options)
  onboarding_create_document_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_create_form => /onboarding/documents(.:format)
  // function(options)
  onboarding_create_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboarding_delete_document_group => /onboarding/documents/group/:id(.:format)
  // function(id, options)
  onboarding_delete_document_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// onboarding_delete_form => /onboarding/documents/:id(.:format)
  // function(id, options)
  onboarding_delete_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_document_versions => /onboarding/documents/:id/versions(.:format)
  // function(id, options)
  onboarding_document_versions_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// onboarding_onboarding_complete_via_oauth => /dashboard/onboarding_complete_via_oauth(.:format)
  // function(options)
  onboarding_onboarding_complete_via_oauth_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"dashboard",false],[2,[7,"/",false],[2,[6,"onboarding_complete_via_oauth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboarding_send_document_group => /onboarding/documents/groups(.:format)
  // function(options)
  onboarding_send_document_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_send_form => /onboarding/documents/send(.:format)
  // function(options)
  onboarding_send_form_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"send",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_setup => /staff/onboarding/setup(.:format)
  // function(options)
  onboarding_setup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"setup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_update_document_group => /onboarding/documents/group/:id(.:format)
  // function(id, options)
  onboarding_update_document_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[6,"group",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// onboarding_update_form => /onboarding/documents/:id(.:format)
  // function(id, options)
  onboarding_update_form_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"onboarding",false],[2,[7,"/",false],[2,[6,"documents",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oncost_config => /oncost_configs/:id(.:format)
  // function(id, options)
  oncost_config_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oncost_configs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oncost_configs => /oncost_configs(.:format)
  // function(options)
  oncost_configs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oncost_configs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// open_hours_period_all_for_location => /open_hours_location/:location_id(.:format)
  // function(location_id, options)
  open_hours_period_all_for_location_path: Utils.route([["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"open_hours_location",false],[2,[7,"/",false],[2,[3,"location_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_hours_period_create => /open_hours_location/:location_id(.:format)
  // function(location_id, options)
  open_hours_period_create_path: Utils.route([["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"open_hours_location",false],[2,[7,"/",false],[2,[3,"location_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_hours_period_delete => /open_hours/:id(.:format)
  // function(id, options)
  open_hours_period_delete_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"open_hours",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_hours_period_update => /open_hours/:id(.:format)
  // function(id, options)
  open_hours_period_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"open_hours",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// open_hours_period_update_all_for_location => /open_hours_location/:location_id(.:format)
  // function(location_id, options)
  open_hours_period_update_all_for_location_path: Utils.route([["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"open_hours_location",false],[2,[7,"/",false],[2,[3,"location_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// options_platform_model_records => /platform/objects/:model_id/records/options(.:format)
  // function(model_id, options)
  options_platform_model_records_path: Utils.route([["model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"model_id",false],[2,[7,"/",false],[2,[6,"records",false],[2,[7,"/",false],[2,[6,"options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// options_positions => /positions/options(.:format)
  // function(options)
  options_positions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"positions",false],[2,[7,"/",false],[2,[6,"options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organisation => /organisations/:id(.:format)
  // function(id, options)
  organisation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// organisation_activity => /organisations/:organisation_id/activity(.:format)
  // function(organisation_id, options)
  organisation_activity_path: Utils.route([["organisation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"organisation_id",false],[2,[7,"/",false],[2,[6,"activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// organisation_bookmark => /organisations/:organisation_id/bookmark(.:format)
  // function(organisation_id, options)
  organisation_bookmark_path: Utils.route([["organisation_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"organisation_id",false],[2,[7,"/",false],[2,[6,"bookmark",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_compliance_reports_platform_store_index => /platform/store/partner_compliance_reports(.:format)
  // function(options)
  partner_compliance_reports_platform_store_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"store",false],[2,[7,"/",false],[2,[6,"partner_compliance_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_reports_platform_store_index => /platform/store/partner_reports(.:format)
  // function(options)
  partner_reports_platform_store_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"store",false],[2,[7,"/",false],[2,[6,"partner_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payroll_integration_staff => /integrations/payroll/:id/external_staff(.:format)
  // function(id, options)
  payroll_integration_staff_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"external_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integration_wizard => /integrations/payroll/:id/edit(*wizard_tab)(.:format)
  // function(id, options)
  payroll_integration_wizard_path: Utils.route([["id",true],["wizard_tab",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[2,[1,[5,[3,"wizard_tab",false],false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]),
// payroll_integration_wizard_allowance => /integrations/payroll/:id/allowances(.:format)
  // function(id, options)
  payroll_integration_wizard_allowance_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"allowances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integration_wizard_award => /integrations/payroll/:id/awards(.:format)
  // function(id, options)
  payroll_integration_wizard_award_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"awards",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integration_wizard_awards_sync => /integrations/payroll/:id/awards/sync(.:format)
  // function(id, options)
  payroll_integration_wizard_awards_sync_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[6,"sync",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// payroll_integration_wizard_cost_tracking => /integrations/payroll/:id/cost_tracking(.:format)
  // function(id, options)
  payroll_integration_wizard_cost_tracking_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cost_tracking",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integration_wizard_data => /integrations/payroll/:id/data(.:format)
  // function(id, options)
  payroll_integration_wizard_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integration_wizard_general => /integrations/payroll/:id/general(.:format)
  // function(id, options)
  payroll_integration_wizard_general_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"general",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integration_wizard_link_staff => /integrations/payroll/:id/link_staff/:staff_id(.:format)
  // function(id, staff_id, options)
  payroll_integration_wizard_link_staff_path: Utils.route([["id",true],["staff_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"link_staff",false],[2,[7,"/",false],[2,[3,"staff_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// payroll_integration_wizard_staff => /integrations/payroll/:id/staff(.:format)
  // function(id, options)
  payroll_integration_wizard_staff_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// payroll_integrations => /integrations/payroll(.:format)
  // function(options)
  payroll_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payroll_pay_run => /payroll/pay_runs/:id(.:format)
  // function(id, options)
  payroll_pay_run_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[6,"pay_runs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// payroll_us_nacha_batches => /payroll/us/nacha_batches(/:date)(.:format)
  // function(options)
  payroll_us_nacha_batches_path: Utils.route([["date",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[6,"us",false],[2,[7,"/",false],[2,[6,"nacha_batches",false],[2,[1,[2,[7,"/",false],[3,"date",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// permission_versions => /platform/permission/:id/versions(.:format)
  // function(id, options)
  permission_versions_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"permission",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// platform => /platform(.:format)
  // function(options)
  platform_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// platform_model => /platform/objects/:id(.:format)
  // function(id, options)
  platform_model_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// platform_model_record => /platform/objects/:model_id/records/:id(.:format)
  // function(model_id, id, options)
  platform_model_record_path: Utils.route([["model_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"model_id",false],[2,[7,"/",false],[2,[6,"records",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// platform_model_records => /platform/objects/:model_id/records(.:format)
  // function(model_id, options)
  platform_model_records_path: Utils.route([["model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[2,[7,"/",false],[2,[3,"model_id",false],[2,[7,"/",false],[2,[6,"records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// platform_models => /platform/objects(.:format)
  // function(options)
  platform_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"objects",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// platform_role => /platform/roles/:id(.:format)
  // function(id, options)
  platform_role_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// platform_role_permissions => /platform/roles/:role_id/permissions(.:format)
  // function(role_id, options)
  platform_role_permissions_path: Utils.route([["role_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"roles",false],[2,[7,"/",false],[2,[3,"role_id",false],[2,[7,"/",false],[2,[6,"permissions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// platform_roles => /platform/roles(.:format)
  // function(options)
  platform_roles_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"roles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// platform_store_index => /platform/store(.:format)
  // function(options)
  platform_store_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"store",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// platform_upload_file => /platform/files(.:format)
  // function(options)
  platform_upload_file_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"files",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pos_integrations => /integrations/pos(.:format)
  // function(options)
  pos_integrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"pos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// post_roster_notifications => /roster/notify_staff/:dates(.:format)
  // function(dates, options)
  post_roster_notifications_path: Utils.route([["dates",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"notify_staff",false],[2,[7,"/",false],[2,[3,"dates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// post_timesheet_reminders => /timesheets/remind(.:format)
  // function(options)
  post_timesheet_reminders_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"remind",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ppt_settings => /ppt_settings(.:format)
  // function(options)
  ppt_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ppt_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// preview_ats_seek_index => /ats/seek/preview(.:format)
  // function(options)
  preview_ats_seek_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ats",false],[2,[7,"/",false],[2,[6,"seek",false],[2,[7,"/",false],[2,[6,"preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// profile => /profile(.:format)
  // function(options)
  profile_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// profile_get_app => /profile/get_app(.:format)
  // function(options)
  profile_get_app_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"get_app",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// profile_leave_and_unavailability_user => /staff/:id/profile/leave_and_unavailability(.:format)
  // function(id, options)
  profile_leave_and_unavailability_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"leave_and_unavailability",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// profile_pay_conditions_user => /staff/:id/profile/pay_conditions(.:format)
  // function(id, options)
  profile_pay_conditions_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"pay_conditions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// profile_payroll_user => /staff/:id/profile/payroll(.:format)
  // function(id, options)
  profile_payroll_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"payroll",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// profile_personal_user => /staff/:id/profile/personal(.:format)
  // function(id, options)
  profile_personal_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"personal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// profile_time_and_attendance_user => /staff/:id/profile/time_and_attendance(.:format)
  // function(id, options)
  profile_time_and_attendance_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"time_and_attendance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// profile_update_time_format => /profile/update_time_format(.:format)
  // function(options)
  profile_update_time_format_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"update_time_format",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// profile_update_user_language => /profile/update_user_language(.:format)
  // function(options)
  profile_update_user_language_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[2,[7,"/",false],[2,[6,"update_user_language",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// publish_schedules => /publish_schedules(.:format)
  // function(options)
  publish_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"publish_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// publishing_info => /publishing_info(.:format)
  // function(options)
  publishing_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"publishing_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rdo_update => /rdos/:id(.:format)
  // function(id, options)
  rdo_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rdos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recache_rosters_and_users => /rosters_overview/recache_rosters_and_users(.:format)
  // function(options)
  recache_rosters_and_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"recache_rosters_and_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// recalc_timesheets => /timesheets/recalc(.:format)
  // function(options)
  recalc_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"recalc",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// regular_hours_as_html => /regular_hours/html_for_dynamic_field(.:format)
  // function(options)
  regular_hours_as_html_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"regular_hours",false],[2,[7,"/",false],[2,[6,"html_for_dynamic_field",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// relevant_staff => /users/relevant_staff(.:format)
  // function(options)
  relevant_staff_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"relevant_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reload_schedules_for_user => /rosters_overview/schedules/reload(.:format)
  // function(options)
  reload_schedules_for_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[6,"reload",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// remove_pay_condition_set => /staff/:id/contract/remove_pay_condition_set(.:format)
  // function(id, options)
  remove_pay_condition_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contract",false],[2,[7,"/",false],[2,[6,"remove_pay_condition_set",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reports_platform_store_index => /platform/store/reports(.:format)
  // function(options)
  reports_platform_store_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"store",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// request_list_leave_index => /leave/requests(.:format)
  // function(options)
  request_list_leave_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"requests",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// retrieve_pay_conditions_data => /staff/:id/contract/get_data(.:format)
  // function(id, options)
  retrieve_pay_conditions_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contract",false],[2,[7,"/",false],[2,[6,"get_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// review_pricing => /account/upgrade(.:format)
  // function(options)
  review_pricing_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"upgrade",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// roster => /roster/:date(.:format)
  // function(date, options)
  roster_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_charts => /roster_charts(.:format)
  // function(options)
  roster_charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster_charts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// roster_comments => /roster_comments(.:format)
  // function(options)
  roster_comments_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster_comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// roster_edit_history => /roster/edit_history(.:format)
  // function(options)
  roster_edit_history_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"edit_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_edit_history_get_managed_locations => /roster/edit_history/get_managed_locations(.:format)
  // function(options)
  roster_edit_history_get_managed_locations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"edit_history",false],[2,[7,"/",false],[2,[6,"get_managed_locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// roster_edit_history_get_versions => /roster/edit_history/get_versions(.:format)
  // function(options)
  roster_edit_history_get_versions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"edit_history",false],[2,[7,"/",false],[2,[6,"get_versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// roster_import => /rosters/import(.:format)
  // function(options)
  roster_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_import_excel => /roster/:date/import/excel(.:format)
  // function(date, options)
  roster_import_excel_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[3,"date",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"excel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// roster_import_qikkids => /roster/:date/import/qikkids(.:format)
  // function(date, options)
  roster_import_qikkids_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[3,"date",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"qikkids",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// roster_notify_staff => /roster/notify_staff(.:format)
  // function(options)
  roster_notify_staff_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"notify_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_patterns_bulk_crud => /roster_patterns/bulk_crud(.:format)
  // function(options)
  roster_patterns_bulk_crud_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster_patterns",false],[2,[7,"/",false],[2,[6,"bulk_crud",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_schedule_acceptance => /roster/schedule_acceptance(.:format)
  // function(options)
  roster_schedule_acceptance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"schedule_acceptance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_snapshots => /rosters(/:date)/snapshots(.:format)
  // function(options)
  roster_snapshots_path: Utils.route([["date",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[1,[2,[7,"/",false],[3,"date",false]],false],[2,[7,"/",false],[2,[6,"snapshots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// roster_template_create => /roster_template(.:format)
  // function(options)
  roster_template_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster_template",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// roster_template_destroy => /roster_template/:id(.:format)
  // function(id, options)
  roster_template_destroy_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster_template",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_template_update => /roster_template/:id(.:format)
  // function(id, options)
  roster_template_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster_template",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// roster_to_timesheet => /roster/:date(/:department_id)/create_timesheets(.:format)
  // function(date, options)
  roster_to_timesheet_path: Utils.route([["date",true],["department_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[3,"date",false],[2,[1,[2,[7,"/",false],[3,"department_id",false]],false],[2,[7,"/",false],[2,[6,"create_timesheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]),
// rosters => /rosters(.:format)
  // function(options)
  rosters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rosters_alt => /roster(.:format)
  // function(options)
  rosters_alt_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rosters_check_status => /rosters/check_status(.:format)
  // function(options)
  rosters_check_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"check_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_for_user => /rosters/for/:user_id(.:format)
  // function(user_id, options)
  rosters_for_user_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"for",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rosters_import_check_status => /rosters/import/check_status(.:format)
  // function(options)
  rosters_import_check_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"check_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rosters_notify_staff => /rosters/notify_staff(.:format)
  // function(options)
  rosters_notify_staff_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"notify_staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview => /rosters_overview(.:format)
  // function(options)
  rosters_overview_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rosters_overview_awards_and_allowances_data => /rosters_overview/awards_and_allowances_data(.:format)
  // function(options)
  rosters_overview_awards_and_allowances_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"awards_and_allowances_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_bulk_schedules => /rosters_overview/bulk_schedules(.:format)
  // function(options)
  rosters_overview_bulk_schedules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"bulk_schedules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_bulk_schedules_destroy => /rosters_overview/bulk_schedules_destroy(.:format)
  // function(options)
  rosters_overview_bulk_schedules_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"bulk_schedules_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_employment_condition_sets_data => /rosters_overview/get_employment_condition_sets_data(.:format)
  // function(options)
  rosters_overview_employment_condition_sets_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"get_employment_condition_sets_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_leave_data => /rosters_overview/leave_data(.:format)
  // function(options)
  rosters_overview_leave_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"leave_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_location_data => /rosters_overview/location_data(.:format)
  // function(options)
  rosters_overview_location_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"location_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_schedule_breaks => /rosters_overview/schedules/:schedule_id/schedule_breaks(.:format)
  // function(schedule_id, options)
  rosters_overview_schedule_breaks_path: Utils.route([["schedule_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"schedule_id",false],[2,[7,"/",false],[2,[6,"schedule_breaks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rosters_overview_schedule_data => /rosters_overview/schedule_data(.:format)
  // function(options)
  rosters_overview_schedule_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"schedule_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_schedules_bulk_sort_order => /rosters_overview/bulk_sort_order(.:format)
  // function(options)
  rosters_overview_schedules_bulk_sort_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"bulk_sort_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_shift_data => /rosters_overview/shift_data(.:format)
  // function(options)
  rosters_overview_shift_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"shift_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_templates_data => /rosters_overview/templates_data(.:format)
  // function(options)
  rosters_overview_templates_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"templates_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_overview_user_data => /rosters_overview/user_data(.:format)
  // function(options)
  rosters_overview_user_data_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"user_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_projection_data => /rosters/projection_data/:date(.:format)
  // function(date, options)
  rosters_projection_data_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"projection_data",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rosters_shift_claiming => /rosters/shift_claiming(.:format)
  // function(options)
  rosters_shift_claiming_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters",false],[2,[7,"/",false],[2,[6,"shift_claiming",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rosters_subscription => /roster/subscribe/:key(.:format)
  // function(key, options)
  rosters_subscription_path: Utils.route([["key",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"subscribe",false],[2,[7,"/",false],[2,[3,"key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// save_award_payroll_configuration => /save_award_payroll_configuration/:id(.:format)
  // function(id, options)
  save_award_payroll_configuration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"save_award_payroll_configuration",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// save_multiple_predictions => /predicted_storestats/save_multiple_predictions(.:format)
  // function(options)
  save_multiple_predictions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"predicted_storestats",false],[2,[7,"/",false],[2,[6,"save_multiple_predictions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// save_prediction => /predicted_storestats/save_prediction(.:format)
  // function(options)
  save_prediction_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"predicted_storestats",false],[2,[7,"/",false],[2,[6,"save_prediction",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// schedule_overview_acceptance => /schedule_overview/acceptance(.:format)
  // function(options)
  schedule_overview_acceptance_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"schedule_overview",false],[2,[7,"/",false],[2,[6,"acceptance",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// scheduled_tasks_update => /notifications_and_tasks/scheduled_tasks/:id(.:format)
  // function(id, options)
  scheduled_tasks_update_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications_and_tasks",false],[2,[7,"/",false],[2,[6,"scheduled_tasks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_current_roster => /roster/notify_staff/current(.:format)
  // function(options)
  send_current_roster_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[6,"notify_staff",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_tfa_token_to_email => /user/tfa/send_email(.:format)
  // function(options)
  send_tfa_token_to_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user",false],[2,[7,"/",false],[2,[6,"tfa",false],[2,[7,"/",false],[2,[6,"send_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings => /settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// settings_feature_management => /settings/feature_management(.:format)
  // function(options)
  settings_feature_management_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"feature_management",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings_onboarding => /settings/onboarding(.:format)
  // function(options)
  settings_onboarding_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"onboarding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings_pay_group => /settings/pay_groups/:id(.:format)
  // function(id, options)
  settings_pay_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"pay_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings_pay_groups => /settings/pay_groups(.:format)
  // function(options)
  settings_pay_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"pay_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// settings_revenue => /integrations/revenue(.:format)
  // function(options)
  settings_revenue_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"revenue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// shift => /shift/:id(.:format)
  // function(id, options)
  shift_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shift",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// shift_applicable_allowances => /shift/:id/applicable_allowances(.:format)
  // function(id, options)
  shift_applicable_allowances_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shift",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"applicable_allowances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// shift_break_create => /shift/:shift_id/breaks(.:format)
  // function(shift_id, options)
  shift_break_create_path: Utils.route([["shift_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shift",false],[2,[7,"/",false],[2,[3,"shift_id",false],[2,[7,"/",false],[2,[6,"breaks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// shift_break_update => /shift/:shift_id/breaks/:id(.:format)
  // function(shift_id, id, options)
  shift_break_update_path: Utils.route([["shift_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shift",false],[2,[7,"/",false],[2,[3,"shift_id",false],[2,[7,"/",false],[2,[6,"breaks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// shift_feedback_options => /shift_feedback_options(.:format)
  // function(options)
  shift_feedback_options_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_feedback_options",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_questions_platform_store_index => /platform/store/shift_questions(.:format)
  // function(options)
  shift_questions_platform_store_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"platform",false],[2,[7,"/",false],[2,[6,"store",false],[2,[7,"/",false],[2,[6,"shift_questions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// shift_slots => /shift_slots(.:format)
  // function(options)
  shift_slots_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_slots",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_broadcast => /shift_swap_plan_broadcast(.:format)
  // function(options)
  shift_swap_plan_broadcast_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_broadcast",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_create => /shift_swap_plan_create(.:format)
  // function(options)
  shift_swap_plan_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_curate => /shift_swap_plan_curate(.:format)
  // function(options)
  shift_swap_plan_curate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_curate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_decline => /shift_swap_plan_decline(.:format)
  // function(options)
  shift_swap_plan_decline_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_decline",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_destroy => /shift_swap_plan_destroy(.:format)
  // function(options)
  shift_swap_plan_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_remind => /shift_swap_plan_remind(.:format)
  // function(options)
  shift_swap_plan_remind_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_remind",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_plan_update_swap_proposal => /shift_swap_plan_update_swap_proposal(.:format)
  // function(options)
  shift_swap_plan_update_swap_proposal_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_plan_update_swap_proposal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_swap_proposal_approve => /shift_swap_proposal_approve(.:format)
  // function(options)
  shift_swap_proposal_approve_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift_swap_proposal_approve",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shift_update_allowances => /shift/:id/allowances(.:format)
  // function(id, options)
  shift_update_allowances_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"shift",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"allowances",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// shifts => /shift(.:format)
  // function(options)
  shifts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shift",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// shifts_within_date_range => /shifts/within_date_range(.:format)
  // function(options)
  shifts_within_date_range_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"shifts",false],[2,[7,"/",false],[2,[6,"within_date_range",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// show_invoice => /show_invoice/:id(/:organisation_id)(.:format)
  // function(id, options)
  show_invoice_path: Utils.route([["id",true],["organisation_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"show_invoice",false],[2,[7,"/",false],[2,[3,"id",false],[2,[1,[2,[7,"/",false],[3,"organisation_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// sso_settings => /integrations/singlesignon(.:format)
  // function(options)
  sso_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"singlesignon",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// staff_search => /users/staff_search(.:format)
  // function(options)
  staff_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"staff_search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// start_to_impersonate => /impersonation/start/:user_id(.:format)
  // function(user_id, options)
  start_to_impersonate_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"impersonation",false],[2,[7,"/",false],[2,[6,"start",false],[2,[7,"/",false],[2,[3,"user_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stop_impersonating => /impersonation/stop(.:format)
  // function(options)
  stop_impersonating_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"impersonation",false],[2,[7,"/",false],[2,[6,"stop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// super_choice_configuration => /feature_managements/super_choice_configuration(.:format)
  // function(options)
  super_choice_configuration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"feature_managements",false],[2,[7,"/",false],[2,[6,"super_choice_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tags_users => /staff/tags(.:format)
  // function(options)
  tags_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// time_off => /time_off(.:format)
  // function(options)
  time_off_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"time_off",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// timeclock_question => /timeclock_questions/:id(.:format)
  // function(id, options)
  timeclock_question_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timeclock_questions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timeclock_questions => /timeclock_questions(.:format)
  // function(options)
  timeclock_questions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timeclock_questions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// timesheet => /timesheets/:id(.:format)
  // function(id, options)
  timesheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timesheet_exports => /timesheet_exports(.:format)
  // function(options)
  timesheet_exports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheet_exports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// timesheets => /timesheets(.:format)
  // function(options)
  timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// timesheets_approve_date => /timesheets/daily/approve_date(.:format)
  // function(options)
  timesheets_approve_date_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"daily",false],[2,[7,"/",false],[2,[6,"approve_date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_award => /awards/:id/timesheets(.:format)
  // function(id, options)
  timesheets_award_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"awards",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"timesheets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_check_status => /timesheets/check_status(.:format)
  // function(options)
  timesheets_check_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"check_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timesheets_create_userlist => /timesheets/create(.:format)
  // function(options)
  timesheets_create_userlist_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timesheets_daily => /timesheets/daily/:date(.:format)
  // function(date, options)
  timesheets_daily_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"daily",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_daily_current => /timesheets/daily/current(.:format)
  // function(options)
  timesheets_daily_current_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"daily",false],[2,[7,"/",false],[2,[6,"current",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_daily_date => /timesheets/daily/:date(.:format)
  // function(date, options)
  timesheets_daily_date_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"daily",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_daily_static => /timesheets/daily/static(.:format)
  // function(options)
  timesheets_daily_static_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"daily",false],[2,[7,"/",false],[2,[6,"static",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_export_for_payroll_integration => /timesheets/export/:payroll_integration_id(.:format)
  // function(payroll_integration_id, options)
  timesheets_export_for_payroll_integration_path: Utils.route([["payroll_integration_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"export",false],[2,[7,"/",false],[2,[3,"payroll_integration_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_export_for_timesheet => /timesheets/:id/export(.:format)
  // function(id, options)
  timesheets_export_for_timesheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_for_user => /timesheets/for/:user(.:format)
  // function(user, options)
  timesheets_for_user_path: Utils.route([["user",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"for",false],[2,[7,"/",false],[2,[3,"user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_import => /timesheets/import(.:format)
  // function(options)
  timesheets_import_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timesheets_import_progress => /timesheets/import/progress(.:format)
  // function(options)
  timesheets_import_progress_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"progress",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// timesheets_importer => /timesheets/import(.:format)
  // function(options)
  timesheets_importer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"import",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timesheets_lookup => /timesheets/on(.:format)
  // function(options)
  timesheets_lookup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"on",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// timesheets_search => /timesheets/search(.:format)
  // function(options)
  timesheets_search_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tip_jar_applies_to => /tip_jars/applies_to(.:format)
  // function(options)
  tip_jar_applies_to_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars",false],[2,[7,"/",false],[2,[6,"applies_to",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tip_jar_create => /tip_jars/create(.:format)
  // function(options)
  tip_jar_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tip_jar_delete => /tip_jars/delete/:id(.:format)
  // function(id, options)
  tip_jar_delete_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars",false],[2,[7,"/",false],[2,[6,"delete",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// tip_jar_update => /tip_jars/update(.:format)
  // function(options)
  tip_jar_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tip_jars => /tip_jars(.:format)
  // function(options)
  tip_jars_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// tip_jars_date => /tip_jars/:date(.:format)
  // function(date, options)
  tip_jars_date_path: Utils.route([["date",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars",false],[2,[7,"/",false],[2,[3,"date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// tip_jars_date_amount_update => /tip_jars_date_amount/update(.:format)
  // function(options)
  tip_jars_date_amount_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"tip_jars_date_amount",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unapprove_timesheet => /timesheets/:id/unapprove(.:format)
  // function(id, options)
  unapprove_timesheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unapprove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unified_calendar_index => /unified_calendar(.:format)
  // function(options)
  unified_calendar_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"unified_calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// unlock_all_timesheets => /timesheets/unlock_all(.:format)
  // function(options)
  unlock_all_timesheets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[6,"unlock_all",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unlock_timesheet => /timesheets/:id/unlock(.:format)
  // function(id, options)
  unlock_timesheet_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"timesheets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_applied_pay_condition_set => /staff/:id/contract/update_applied_pay_condition_set(.:format)
  // function(id, options)
  update_applied_pay_condition_set_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"contract",false],[2,[7,"/",false],[2,[6,"update_applied_pay_condition_set",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_custom_payroll_integration => /integrations/payroll/custom/:id(.:format)
  // function(id, options)
  update_custom_payroll_integration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"payroll",false],[2,[7,"/",false],[2,[6,"custom",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_data_stream => /integrations/data/data_stream/:id(.:format)
  // function(id, options)
  update_data_stream_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[7,"/",false],[2,[6,"data_stream",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_ppt_awards_settings => /update_ppt_settings(.:format)
  // function(options)
  update_ppt_awards_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"update_ppt_settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_projections_table_configuration => /roster/:date/:daily_schedule_id/projections_table_configurations(.:format)
  // function(date, daily_schedule_id, options)
  update_projections_table_configuration_path: Utils.route([["date",true],["daily_schedule_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"roster",false],[2,[7,"/",false],[2,[3,"date",false],[2,[7,"/",false],[2,[3,"daily_schedule_id",false],[2,[7,"/",false],[2,[6,"projections_table_configurations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_roster_ratio_compat => /integrations/data/roster-ratio-compat(.:format)
  // function(options)
  update_roster_ratio_compat_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[7,"/",false],[2,[6,"roster-ratio-compat",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_roster_ratios => /integrations/data/roster-ratios(.:format)
  // function(options)
  update_roster_ratios_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"data",false],[2,[7,"/",false],[2,[6,"roster-ratios",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_rosters_overview_schedule_break => /rosters_overview/schedules/:schedule_id/schedule_breaks/:id(.:format)
  // function(schedule_id, id, options)
  update_rosters_overview_schedule_break_path: Utils.route([["schedule_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rosters_overview",false],[2,[7,"/",false],[2,[6,"schedules",false],[2,[7,"/",false],[2,[3,"schedule_id",false],[2,[7,"/",false],[2,[6,"schedule_breaks",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// update_stat_type => /stat_type/:id(.:format)
  // function(id, options)
  update_stat_type_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"stat_type",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_status_leave_index => /leave/update_status(.:format)
  // function(options)
  update_status_leave_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"leave",false],[2,[7,"/",false],[2,[6,"update_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_super_choice_configuration => /feature_managements/super_choice_configuration/:id(.:format)
  // function(id, options)
  update_super_choice_configuration_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"feature_managements",false],[2,[7,"/",false],[2,[6,"super_choice_configuration",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_user_qualifications => /update_user_qualifications(.:format)
  // function(options)
  update_user_qualifications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"update_user_qualifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_bulk_update_sort_order => /user_bulk_update_sort_order(.:format)
  // function(options)
  user_bulk_update_sort_order_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_bulk_update_sort_order",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_contract => /user_contracts/:id(.:format)
  // function(id, options)
  user_contract_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_contracts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_data => /organisations/:id/user_data(.:format)
  // function(id, options)
  user_data_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"organisations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"user_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_employment_condition_set => /staff/:user_id/employment_condition_sets/:id(.:format)
  // function(user_id, id, options)
  user_employment_condition_set_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_condition_sets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_employment_condition_sets => /staff/:user_id/employment_condition_sets(.:format)
  // function(user_id, options)
  user_employment_condition_sets_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_condition_sets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_import_refine => /staff/import/refine(.:format)
  // function(options)
  user_import_refine_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[6,"import",false],[2,[7,"/",false],[2,[6,"refine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_signed_onboarding_forms => /user_signed_onboarding_forms(.:format)
  // function(options)
  user_signed_onboarding_forms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_signed_onboarding_forms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users => /staff(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// validate_password => /try/validate_password(.:format)
  // function(options)
  validate_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"try",false],[2,[7,"/",false],[2,[6,"validate_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// versions_user_employment_condition_set => /staff/:user_id/employment_condition_sets/:id/versions(.:format)
  // function(user_id, id, options)
  versions_user_employment_condition_set_path: Utils.route([["user_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"staff",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"employment_condition_sets",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"versions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// view_allowances => /allowances(.:format)
  // function(options)
  view_allowances_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"allowances",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  }

  return result;

}).call(this);

