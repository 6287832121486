// @flow
import * as Req from "helpers/request"
import * as Routes from "helpers/routes"
import * as Model from "../models/model"
import * as Record from "../models/record"
import * as State from "../models/state"

export function getUrl(url: string): Promise<mixed> {
  return Req.get(url).then((res) => res.data)
}

export function getResource(url: string): Promise<State.Schema> {
  return Req.get(url).then((res: Req.Response): State.Schema => State.fromJson(res.data))
}

export function uploadFile(file: File): Promise<Record.Schema> {
  const data = new FormData()
  data.append("file", file)

  return Req.post(Routes.platform_upload_file_path(), data).then((res: Req.Response): Record.Schema =>
    Record.fromJson(res.data)
  )
}

export function platformObjectsIndex(): Promise<State.Schema> {
  return Req.get(Routes.platform_models_path({ format: "json" })).then((res: Req.Response): State.Schema =>
    State.fromJson(res.data)
  )
}

export function createModel(model: Model.Schema): Promise<Model.Schema> {
  return Req.post(Routes.platform_models_path(), Model.toPayload(model)).then((res: Req.Response) =>
    Model.fromJson(res.data)
  )
}

export function updateModel(model: Model.Schema): Promise<Model.Schema> {
  return Req.put(Routes.platform_model_path(model.id), Model.toPayload(model)).then((res: Req.Response) =>
    Model.fromJson(res.data)
  )
}

export function deleteModel(model: Model.Schema): Promise<null> {
  return Req.del(Routes.platform_model_path(model.id)).then((res: Req.Response) => null)
}

export function createRecord(model: Model.Schema, record: Record.Schema): Promise<Record.Schema> {
  const updatedRecord = Record.updateRecordAssocs(record, model.associations)
  return Req.post(Routes.platform_model_records_path(model.id), Record.toPayload(updatedRecord)).then(
    (res: Req.Response) => Record.fromJson(res.data)
  )
}

export function updateRecord(model: Model.Schema, record: Record.Schema): Promise<Record.Schema> {
  return Req.put(Routes.platform_model_record_path(model.id, record.id), Record.toPayload(record)).then(
    (res: Req.Response) => Record.fromJson(res.data)
  )
}

export function deleteRecord(model: Model.Schema, record: Record.Schema): Promise<Record.Schema> {
  return Req.del(Routes.platform_model_record_path(model.id, record.id), Record.toPayload(record)).then(
    (res: Req.Response) => Record.fromJson(res.data)
  )
}
