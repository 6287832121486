// @flow

import { combineActions } from "redux-em"
import * as reqProcess from "./reqProcess"
import * as shiftThunks from "./shifts/thunks"
import * as timesheetsThunks from "./timesheets/thunks"
import { appActions } from "./app"
import { reqCacheActions } from "./reqCache"
import { reqBreakCacheActions } from "./reqBreakCache"
import { reqRecordActions } from "./reqRecord"
import { shiftActions } from "./shifts"
import { summaryActions } from "./summary"
import { timesheetsActions } from "./timesheets"
import { userActions } from "./user"
import { replaceState } from "./replaceableState"

/**
 * the full timesheet actions collection
 */
export default (combineActions([
  appActions,
  reqCacheActions,
  reqBreakCacheActions,
  reqProcess,
  reqRecordActions,
  shiftActions,
  shiftThunks,
  summaryActions,
  timesheetsActions,
  timesheetsThunks,
  userActions,
  { replaceState },
  // eslint-disable-next-line flowtype/no-weak-types
]): any)
