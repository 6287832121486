import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    uniqueId: String,
    uselocal: Boolean,
  }

  static targets = ["caret", "dropdown", "expandBtn"]

  connect() {
    if (window.localStorage.getItem(this.uniqueIdValue) === "closed") {
      this.element.remove()
    }
  }

  dismiss() {
    if (this.uselocalValue === true) {
      window.localStorage.setItem(this.uniqueIdValue, "closed")
      this.element.remove()
    } else {
      this.element.remove()
    }
  }

  expand() {
    if (this.dropdownTarget.classList.contains("grid-rows-[0fr]")) {
      this.caretTarget.classList.add("-rotate-180", "transition-all", "duration-300")
      this.dropdownTarget.classList.replace("grid-rows-[0fr]", "grid-rows-[1fr]")
      this.expandBtnTarget.setAttribute("aria-expanded", "true")
    } else {
      this.caretTarget.classList.remove("-rotate-180")
      this.dropdownTarget.classList.replace("grid-rows-[1fr]", "grid-rows-[0fr]")
      this.dropdownTarget.querySelector(".overflow-visible").classList.replace("overflow-visible", "overflow-hidden")
      this.expandBtnTarget.setAttribute("aria-expanded", "false")
    }
  }
}
