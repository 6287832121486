// @flow
import _ from "lodash"

export type KeyStatTypes =
  | null
  | "avg_cost_per_rostered_hour"
  | "base_rate"
  | "sales_per_labor_hour"
  | "sales_per_labor_hour_vs_budget"
  | "total_hours"
  | "total_hrs_incl_leave"
  | "total_hrs_incl_leave_and_unpaid"
  | "preferred_hours"
  | "total_sales"
  | "total_schedule_costs"
  | "total_schedules"
  | "total_schedule_overtime_costs"
  | "wage_percentage_of_revenue"
  | "wage_percentage_of_revenue_with_oncosts"
  | "wage_percentage_of_revenue_vs_budget"
  | "total_recommended_hours"
  | "labour_budget"
  | "total_schedule_costs_with_oncosts"
  | "total_hours_and_sales_per_labor_hour"
  | "total_sales_and_wage_percentage_of_revenue"
  | "total_schedule_costs_and_avg_cost_per_rostered_hour"
  | "projected_sales_and_wage_percentage_of_revenue"
  | "total_schedule_costs_and_labour_budget"
  | "total_hours_and_labour_budget"
  | "total_hours_and_recommended_hours"
  | "vacant_schedules_count"
  | "predicted_employee_costs_vs_budgeted_employee_costs"
  | "predicted_employee_hours_vs_budgeted_employee_hours"
  | "predicted_sales_vs_budgeted_sales"
  | "predicted_splh_vs_budgeted_splh"
  | "predicted_wage_as_pct_revenue_vs_budgeted_wage_as_pct_revenue"
export const refineKeyStatType = (value: ?string, defaultStatType?: KeyStatTypes): KeyStatTypes => {
  switch (value) {
    case "avg_cost_per_rostered_hour":
      return "avg_cost_per_rostered_hour"
    case "sales_per_labor_hour":
      return "sales_per_labor_hour"
    case "sales_per_labor_hour_vs_budget":
      return "sales_per_labor_hour_vs_budget"
    case "total_hours_and_sales_per_labor_hour":
      return "total_hours_and_sales_per_labor_hour"
    case "total_hours":
      return "total_hours"
    case "total_hrs_incl_leave":
      return "total_hrs_incl_leave"
    case "total_hrs_incl_leave_and_unpaid":
      return "total_hrs_incl_leave_and_unpaid"
    case "preferred_hours":
      return "preferred_hours"
    case "total_sales":
      return "total_sales"
    case "total_schedule_costs":
      return "total_schedule_costs"
    case "total_schedule_overtime_costs":
      return "total_schedule_overtime_costs"
    case "total_sales_and_wage_percentage_of_revenue":
      return "total_sales_and_wage_percentage_of_revenue"
    case "total_schedule_costs_and_avg_cost_per_rostered_hour":
      return "total_schedule_costs_and_avg_cost_per_rostered_hour"
    case "total_schedule_costs_and_labour_budget":
      return "total_schedule_costs_and_labour_budget"
    case "total_hours_and_labour_budget":
      return "total_hours_and_labour_budget"
    case "total_hours_and_recommended_hours":
      return "total_hours_and_recommended_hours"
    case "total_schedules":
      return "total_schedules"
    case "wage_percentage_of_revenue":
      return "wage_percentage_of_revenue"
    case "wage_percentage_of_revenue_with_oncosts":
      return "wage_percentage_of_revenue_with_oncosts"
    case "wage_percentage_of_revenue_vs_budget":
      return "wage_percentage_of_revenue_vs_budget"
    case "total_recommended_hours":
      return "total_recommended_hours"
    case "labour_budget":
      return "labour_budget"
    case "base_rate":
      return "base_rate"
    case "total_schedule_costs_with_oncosts":
      return "total_schedule_costs_with_oncosts"
    case "vacant_schedules_count":
      return "vacant_schedules_count"
    case "predicted_employee_costs_vs_budgeted_employee_costs":
      return "predicted_employee_costs_vs_budgeted_employee_costs"
    case "predicted_employee_hours_vs_budgeted_employee_hours":
      return "predicted_employee_hours_vs_budgeted_employee_hours"
    case "predicted_sales_vs_budgeted_sales":
      return "predicted_sales_vs_budgeted_sales"
    case "predicted_splh_vs_budgeted_splh":
      return "predicted_splh_vs_budgeted_splh"
    case "predicted_wage_as_pct_revenue_vs_budgeted_wage_as_pct_revenue":
      return "predicted_wage_as_pct_revenue_vs_budgeted_wage_as_pct_revenue"
    default:
      return null
  }
}

export type KeyStats = {|
  stat_1: KeyStatTypes,
  stat_2: KeyStatTypes,
  stat_3: KeyStatTypes,
|}
export type ReportingViewType = "stats" | "day_breakdown"
export type RosterViewType = "staff" | "stacked"
export type DayViewGraphModeType = "graph" | "table"
export type DayViewRosterViewType = "staff" | "stacked"
export type GroupType = "none" | "team" | "team_group" | "location" | "shift" | "position"
export type Panels = null | "events" | "quick_build" | "cognitive" | "stats" | "tools" | "locations"
export type SortStaffOptions = "first_name" | "last_name" | "custom"
export type SortScheduleOptions =
  | "custom"
  | "team"
  | "start_time"
  | "finish_time"
  | "id"
  | "length"
  | "user_first_name"
  | "user_last_name"

export const refineSortStaff = (unrefined: ?string): SortStaffOptions => {
  switch (unrefined) {
    case "first_name":
      return "first_name"
    case "last_name":
      return "last_name"
    case "custom":
      return "custom"
    default:
      return "first_name"
  }
}

export const refineActiveReportingViewType = (unrefined: ?string): ReportingViewType => {
  switch (unrefined) {
    case "stats":
      return "stats"
    case "day_breakdown":
      return "day_breakdown"
    default:
      return "stats"
  }
}

export const refineRosterViewType = (unrefined: ?string): RosterViewType => {
  switch (unrefined) {
    case "staff":
      return "staff"
    case "stacked":
      return "stacked"
    default:
      return "stacked"
  }
}

export const refineDayViewRosterViewType = (unrefined: ?string): DayViewRosterViewType => {
  switch (unrefined) {
    case "staff":
      return "staff"
    case "stacked":
      return "stacked"
    default:
      return "stacked"
  }
}

export type PrintingOptionsType = {|
  day_by_day: boolean,
  show_reasons: boolean,
  show_recommended_distributions: boolean,
  show_time_off: boolean,
  show_time_off_day_view: boolean,
  simulate_landscape_a4: boolean,
  simulate_portrait_a4: boolean,
|}

export type CustomSortOrder = {
  [id: string]: ?number,
}
export type CustomSort = {|
  user: CustomSortOrder,
|}

export const serializeCustomOrder = (order: CustomSortOrder): string =>
  _.toPairs(order)
    .map(([u_id, idx]) => `${u_id}~${idx}`)
    .join(",")

export const deSerializeCustomOrder = (order: string): CustomSortOrder => {
  if (!order) {
    return {}
  } else {
    return _.fromPairs(
      order
        .split(",")
        .map((line) => line.split("~"))
        .filter((line) => line[0] && line[1])
        .map((line) => [line[0], Number(line[1])])
    )
  }
}

export type Schema = {|
  active_panel: Panels,
  active_reporting_view: ReportingViewType,
  collapse_multiple_cards: boolean,
  custom_sort: CustomSort,
  day_key_stat: KeyStatTypes,
  day_key_stat_three: KeyStatTypes,
  day_key_stat_two: KeyStatTypes,
  day_view_graph_mode: DayViewGraphModeType,
  day_view_roster_view: DayViewRosterViewType,
  editing_sort_staff: boolean,
  expanded_groups: Array<string>,
  group: GroupType,
  hide_people_with_no_shifts: boolean,
  highlight_overtime: boolean,
  highlight_shifts_needing_acceptance: boolean,
  highlight_unpublished_shifts: boolean,
  highlight_vacant_shifts: boolean,
  highlight_validation_errors: boolean,
  key_stats: KeyStats,
  only_show_time_off: boolean,
  prev_schedule_sort: SortScheduleOptions,
  printing_options: PrintingOptionsType,
  roster_view: RosterViewType,
  schedule_sort: SortScheduleOptions,
  schedule_sort_lock: boolean,
  show_staff_ratings: boolean,
  show_teams_on_small_cards: boolean,
  sidebar_collapsed: boolean,
  sort_staff: SortStaffOptions,
  user_key_stat: KeyStatTypes,
|}

export const refineGroupType = (unrefined: ?string): GroupType => {
  switch (unrefined) {
    case "location":
      return "location"
    case "team_group":
      return "team_group"
    case "team":
      return "team"
    case "shift":
      return "shift"
    case "position":
      return "position"
    case "none":
      return "none"
    default:
      return "team"
  }
}

export const refineSortType = (unrefined: ?string): SortScheduleOptions => {
  switch (unrefined) {
    case "custom":
      return "custom"
    case "start_time":
      return "start_time"
    case "finish_time":
      return "finish_time"
    case "team":
      return "team"
    case "length":
      return "length"
    case "id":
      return "id"
    case "user_first_name":
      return "user_first_name"
    case "user_last_name":
      return "user_last_name"
    default:
      return "custom"
  }
}

export const refineDayViewGraphMode = (unrefined: ?string): DayViewGraphModeType => {
  switch (unrefined) {
    case "graph":
      return "graph"
    case "table":
      return "table"
    default:
      return "graph"
  }
}

export default refineGroupType
